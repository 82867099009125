import React from "react";
import { useNavigate, useLocation } from "react-router";
import { useAuthContext } from "../utils/AuthContext";
import { Typography } from "@mui/material";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

const SignIn = () => {
  const app = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();

  function authRedirect() {
    const allowedAuthRoutes = [
      "/auth",
      "/lookup",
      "/dashboard",
      "/recruter",
      "selectEvent",
    ];
    const path = location.pathname;
    if (!allowedAuthRoutes.includes(path)) {
      navigate("/recruiter");
    }
  }

  return (
    <div>
      <button
        style={{
          borderRadius: 4,
          backgroundColor: "#c8132e",
          color: "#ffffff",
          borderColor: "#c8132e",
        }}
        onClick={() => {
          authRedirect();
          app.signIn();
          const name = app.getAccountName();
          console.log("Name: ", name);
        }}
      >
        <AuthenticatedTemplate>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { md: "24px", sm: "20px", xs: "16px" },
              padding: "0.25em",
            }}
          >
            {"Switch Accounts"}
          </Typography>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { md: "24px", sm: "20px", xs: "16px" },
              padding: "0.25em",
            }}
          >
            {"Sign In"}
          </Typography>
        </UnauthenticatedTemplate>
      </button>
    </div>
  );
};

export default SignIn;
