import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useEventContext } from "../../utils/EventContext";
import { useAuthContext } from "../../utils/AuthContext";

import { getAllEventConfigs } from "../../controllers/event";
import { getDefaultEventConfig } from "../../controllers/event";
import { getEventConfigByKey } from "../../controllers/event";

import { Typography } from "@mui/material";
import { Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import Header from "../../Components/Header";
import { FaSync } from "react-icons/fa";
import { FaPencilAlt } from "react-icons/fa";
import SignIn from "../../Components/SignIn";

const SelectEvent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const eventContext = useEventContext();
  const app = useAuthContext();

  const [eventConfigurations, setEventConfigurations] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [search, setSearch] = useState("");
  const [newEventConfig, setNewEventConfig] = useState(null);

  const prepareDefaultEventConfigForNewEvent = async () => {
    let defaultConfig = await getDefaultEventConfig();
    const preppedConfig = {
      ...defaultConfig,
      Title: "",
      EventName: "",
      EventDate: "",
      Description: "",
      EventKey: null,
      id: null,
      Links: {},
    };

    setNewEventConfig(preppedConfig);
  };

  async function createNewEventConfig() {
    if (newEventConfig) {
      navigate("/configureEvent", {
        state: newEventConfig,
      });
    }
  }

  async function getAllEventConfigurations() {
    setIsRefreshing(true);
    await getAllEventConfigs()
      .then((records) => {
        if (records) {
          let eventConfigs = records.map((event) => {
            return event.fields;
          });
          // eslint-disable-next-line

          //Do not display the default event in the event selection page
          eventConfigs = eventConfigs.filter((event) => {
            return event.Title !== "Default";
          });

          setEventConfigurations(eventConfigs.reverse());
        }
        setIsRefreshing(false);
      })
      .catch((error) => {
        setIsRefreshing(false);
      });
  }

  function verifyEventConfig() {
    if (eventContext.eventConfig.EventKey) {
      return;
    } else if (
      queryParams.get("eventKey") &&
      queryParams.get("eventKey") !== "null" &&
      queryParams.get("eventKey") !== eventContext.eventConfig.EventKey
    ) {
      getEventConfigByKey(queryParams.get("eventKey")).then((eventConfig) => {
        if (
          eventConfig &&
          eventConfig.EventKey === queryParams.get("eventKey")
        ) {
          eventContext.setEventConfig(eventConfig);
        } else {
          navigate("/getEventError");
        }
      });
    }
  }

  useEffect(() => {
    app.initAuthSecurePage();
    if (app.accountName) {
      verifyEventConfig();
      getAllEventConfigurations();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.accountName]);

  useEffect(() => {
    prepareDefaultEventConfigForNewEvent();
  }, []);

  return (
    <>
      <AuthenticatedTemplate>
        <Container className="Student">
          <Header></Header>
          <>
            <h3 className="text-center" style={{ marginLeft: ".5em" }}>
              Select your Recruiting Event
            </h3>
            <Form>
              <Form.Group>
                <InputGroup
                  style={{
                    marginBottom: ".75em",
                    marginTop: ".25em",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Form.Control
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Lookup an event..."
                    style={{
                      borderRadius: 4,
                      borderColor: "#0x0x93",
                      width: "85%",
                      zIndex: 0,
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Form>
            <Table bordered hover style={{ m: "1em" }}>
              <thead>
                <tr style={{ color: "#FFF", backgroundColor: "#000093" }}>
                  <th
                    style={{
                      display: "flex",
                    }}
                  >
                    <Typography style={{ fontSize: "14px" }}>
                      Event Name
                    </Typography>
                    <FaSync
                      className={isRefreshing ? "loaderIcon" : ""}
                      style={{ marginLeft: ".5em", verticalAlign: "center" }}
                      onClick={() => {
                        getAllEventConfigurations();
                      }}
                    />
                  </th>
                  <th style={{ textAlign: "center" }}>
                    {" "}
                    <Typography style={{ fontSize: "14px" }}>
                      Event Date
                    </Typography>
                  </th>
                  {
                    //Only display edit column header to admins or leads
                    //(app.isAdmin || app.isRecruitmentLead) && (
                    <th style={{ textAlign: "center" }}>
                      <Typography style={{ fontSize: "14px" }}>Edit</Typography>
                    </th>
                    //)
                  }
                </tr>
              </thead>
              <tbody>
                {eventConfigurations
                  .filter((event) => {
                    return search.toLowerCase() === ""
                      ? event.EventName
                      : event.EventName.toLowerCase().includes(
                          search.toLowerCase()
                        );
                  })
                  .map((event, index) => (
                    <tr key={index}>
                      <td
                        onClick={() => {
                          eventContext.setEventConfig(event);
                          navigate(`/lookup?eventKey=${event.EventKey}`);
                        }}
                      >
                        {event.EventName}
                      </td>
                      <td
                        style={{ textAlign: "center" }}
                        onClick={() => {
                          eventContext.setEventConfig(event);
                          navigate(`/lookup?eventKey=${event.EventKey}`);
                        }}
                      >
                        {" "}
                        {event.EventDate}
                      </td>
                      {
                        //Only display edit button if user is admin or lead
                        //(app.isAdmin || app.isRecruitmentLead) && (
                        <td
                          style={{ textAlign: "center" }}
                          onClick={() => {
                            navigate("/configureEvent", {
                              state: event,
                              search: `?eventKey=${event.EventKey}`,
                            });
                          }}
                        >
                          <FaPencilAlt style={{ fontSize: "17px" }} />
                        </td>
                        //)
                      }
                    </tr>
                  ))}
              </tbody>
            </Table>
            <div style={{ display: "flex" }}>
              {
                //only display create new event button if user is  admin or lead
                //(app.isAdmin || app.isRecruitmentLead) && (
                <Form.Group
                  style={{ display: "flex", m: "0.5em", allignItems: "center" }}
                >
                  <h4 style={{ marginLeft: ".1em" }}>Don't see your event?</h4>
                  <button
                    style={{
                      borderRadius: 4,
                      backgroundColor: "#c8132e",
                      color: "#ffffff",
                      borderColor: "#c8132e",
                      marginLeft: "1em",
                      float: "right",
                    }}
                    onClick={() => {
                      createNewEventConfig();
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: { md: "16px", sm: "12px", xs: "12px" },
                        padding: "0.12em",
                      }}
                    >
                      {"Create New Event"}
                    </Typography>
                  </button>
                </Form.Group>
                //)
              }

              {
                //Only display button to navigate to "Update Recruitment Leads" page to admins
                /*app.isAdmin ||
                (app.accountEmail === "CHamm2@its.jnj.com" && (
                  <Form.Group
                    style={{
                      display: "flex",
                      m: "0.5em",
                      allignItems: "center",
                    }}
                  >
                    <h4 style={{ marginLeft: ".5em" }}>
                      Update Recruitment Leads:
                    </h4>
                    <button
                      style={{
                        borderRadius: 4,
                        backgroundColor: "#c8132e",
                        color: "#ffffff",
                        borderColor: "#c8132e",
                        marginLeft: "1em",
                        float: "right",
                      }}
                      onClick={() => {
                        navigate("/selectRecruitmentLeads");
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: { md: "16px", sm: "12px", xs: "12px" },
                          padding: "0.12em",
                        }}
                      >
                        {"See the List"}
                      </Typography>
                    </button>
                  </Form.Group>
                      ))*/
              }
            </div>
          </>
        </Container>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SignIn></SignIn>
      </UnauthenticatedTemplate>
    </>
  );
};

export default SelectEvent;
