import React from "react";
import { useNavigate } from "react-router-dom";
import { useEventContext } from "../utils/EventContext";
import { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

const Header = (props) => {
  const event = useEventContext();

  let [header, setHeader] = useState("");
  let [description, setDescription] = useState("");
  const navigationOptions = [
    { label: "Dashboard", value: "/dashboard" },
    { label: "Lookup", value: "/lookup" },
    { label: "Registration", value: "/" },
    { label: "Select Event", value: "/selectEvent" },
  ];
  const navigate = useNavigate();
  useEffect(() => {
    if (event.eventConfig && event.eventConfig.id !== null) {
      setHeader(event.eventConfig.EventName);
      setDescription(event.eventConfig.Description);
    } else {
      setHeader("J&J Campus Recruiting");
      setDescription("");
    }
  }, [event.eventConfig]);
  return (
    <>
      <AuthenticatedTemplate>
        <div
          className="container"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <a href="/recruiter">
            <img
              src="/jnj_new_logo.png"
              alt="/JNJ Logo"
              style={{ width: "70%", height: "70%", float: "left" }}
            ></img>
          </a>
          <div
            className="navigationBar"
            style={{
              width: "150%",
              // position: "absolute",
              // top: "8%",
              // right: "20%",
              paddingRight: "2%",
              fontWeight: "bold",
              color: "#000093",
              zIndex: 2,
            }}
          >
            <Select
              placeholder="Navigate"
              options={navigationOptions}
              onChange={(e) => {
                if (event.eventConfig.EventKey) {
                  navigate(e.value + `?eventKey=${event.eventConfig.EventKey}`);
                } else {
                  navigate(e.value);
                }
              }}
            />
          </div>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <img
          src="/jnj_new_logo.png"
          alt="/JNJ Logo"
          style={{ width: "50%", height: "50%" }}
        ></img>
      </UnauthenticatedTemplate>
      <h2 style={{ paddingLeft: "1.5%" }}>
        {header} <br />
        {description}
      </h2>
    </>
  );
};

export default Header;
