import React, { useContext, createContext, useState } from "react";
import { getEventConfigByKey } from "../controllers/event";

const baseEventConfig = {
  Title: "",
  EventName: "",
  Description: "",
  EventDate: "",
  EventKey: null,
  id: null,
  Majors: [],
  Roles: [],
  Links: {},
  Capabilities: [],
  Schools: [],
  Tags: [],
  Years: [],
  NameVisible: true,
  NameRequired: true,
  EmailVisible: true,
  EmailRequired: true,
  PhoneNumberVisible: true,
  PhoneNumberRequired: true,
  SchoolVisible: true,
  SchoolRequired: true,
  GradMonthVisible: true,
  GradMonthRequired: true,
  GradYearVisible: true,
  GradYearRequired: true,
  MajorVisible: true,
  MajorRequired: true,
  GPAVisible: true,
  GPARequired: true,
  SponsorshipVisible: true,
  SponsorshipRequired: true,
  PreviouslyJJVisible: true,
  PreviouslyJJRequired: true,
  RelocationVisible: true,
  RelocationRequired: true,
  RoleInterestVisible: true,
  RoleInterestRequired: true,
  CapabilityInterestVisible: true,
  CapabilityInterestRequired: true,
  ResumeVisible: true,
  ResumeRequired: true,
};

const appContext = createContext({
  eventConfig: baseEventConfig,
  setEventConfig: () => {},
  verifyEventConfig: () => {},
});

export function useEventContext() {
  return useContext(appContext);
}

export default function ProvideEventContext({ children }) {
  const event = useProvideEventContext();
  return <appContext.Provider value={event}>{children}</appContext.Provider>;
}

function verifyEventConfig(queryParams, navigate) {
  if (
    (!queryParams.get("eventKey") || queryParams.get("eventKey") === "null") &&
    !this.eventConfig.EventKey
  ) {
    navigate("/selectEvent");
  } else if (
    queryParams.get("eventKey") &&
    this.eventConfig.EventKey !== queryParams.get("eventKey")
  ) {
    getEventConfigByKey(queryParams.get("eventKey"))
      .then((sharepointConfig) => {
        if (
          sharepointConfig &&
          sharepointConfig.EventKey === queryParams.get("eventKey")
        ) {
          this.setEventConfig(sharepointConfig);
        } else {
          console.log("Event not found: ", queryParams.get("eventKey"));
          navigate("/getEventError");
        }
      })
      .catch((error) => {
        console.log("Error verifying config: ", error);
        navigate("/getEventError");
      });
  }
}

function useProvideEventContext() {
  const [eventConfig, setEventConfig] = useState(baseEventConfig);

  return {
    eventConfig,
    baseEventConfig,
    setEventConfig,
    verifyEventConfig,
  };
}
