import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useAuthContext } from "../../utils/AuthContext";
import { useEventContext } from "../../utils/EventContext";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { Typography } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { getStudent } from "../../controllers/students";
import SignIn from "../../Components/SignIn";
import Header from "../../Components/Header";
import { FaSync } from "react-icons/fa";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const StudentPreview = () => {
  const app = useAuthContext();
  const event = useEventContext();

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [student, setStudent] = useState({});

  function verifyLinkParameters() {
    if (!queryParams.get("eventKey") && !event.eventConfig.EventKey) {
      navigate("/selectEvent");
    } else if (queryParams.get("eventKey") && !queryParams.get("student")) {
      navigate(`/lookup?eventKey=${event.eventConfig.EventKey}`);
    } else if (event.eventConfig.EventKey && !queryParams.get("student")) {
      navigate(`/lookup?eventKey=${event.eventConfig.EventKey}`);
    }
  }

  function verifyStudentData() {
    if (app.getAccountName()) {
      if (state) {
        console.log("Setting student from state");
        setStudent(state);
      }

      getStudent(queryParams.get("student"))
        .then((newStudent) => {
          console.log("Student from sharepoint: ", newStudent);
          if (
            newStudent &&
            newStudent.id &&
            newStudent.id === queryParams.get("student")
          ) {
            setStudent(newStudent);
          } else {
            navigate("/getStudentError");
          }
        })
        .catch((error) => {
          console.log("Get Student Error: ", error);
          navigate("/getStudentError");
        });
    }
  }

  useEffect(() => {
    app.initAuthSecurePage();
    verifyLinkParameters();
    event.verifyEventConfig(queryParams, navigate);
    verifyStudentData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.accountName]);

  return (
    <>
      <AuthenticatedTemplate>
        <Container className="Student">
          <Header></Header>
          <Form.Group
            style={{
              width: "32%",
              position: "absolute",
              top: "8%",
              right: "15%",
              fontWeight: "bold",
              color: "#000093",
            }}
          ></Form.Group>
          <Form>
            <Form.Group>
              <h4 style={{ fontWeight: "bold" }} className="text-center">
                Speaking with
              </h4>
              {student.Name && (
                <h1 style={{ fontWeight: "bold" }} className="text-center">
                  {student.Name}{" "}
                </h1>
              )}
            </Form.Group>
            <Form.Group
              style={{ display: "Flex", justifyContent: "center" }}
              className="center"
            >
              <Button
                onClick={() => {
                  window.open(student.Resume, "_blank");
                }}
                style={{
                  margin: "1em",
                  borderRadius: 4,
                  backgroundColor: "#ffffff",
                  color: "#000093",
                  borderColor: "#000093",
                  borderWidth: "4px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: { md: "24px", sm: "20px", xs: "16px" },
                  }}
                >
                  {"View Resume"}
                </Typography>
              </Button>
              <Button
                style={{
                  margin: "1em",
                  borderRadius: 4,
                  backgroundColor: "#000093",
                  borderColor: "#000093",
                  borderWidth: "4px",
                  color: "#ffffff",
                }}
                onClick={() => {
                  navigate(
                    `/student?eventKey=${event.eventConfig.EventKey}&student=${student.id}`,
                    { state: student }
                  );
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: { md: "24px", sm: "20px", xs: "16px" },
                  }}
                >
                  {"Add Note"}
                </Typography>
              </Button>
            </Form.Group>
            <Form.Group
              style={{
                display: "Flex",
                margin: ".25em",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  justifyContent: "center",
                }}
              >
                Notes History
              </Typography>
            </Form.Group>
            {student.NotesHistory ? (
              student.NotesHistory.map((record, index) => {
                return (
                  <Form.Group
                    key={index}
                    style={{
                      display: "flex",
                      margin: ".25em",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      id={index}
                      sx={{
                        fontSize: "16px",
                        justifyContent: "center",
                      }}
                    >
                      {record}
                    </Typography>
                  </Form.Group>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  margin: ".25em",
                  justifyContent: "center",
                }}
              >
                <FaSync
                  style={{
                    fontSize: "24px",
                    color: "#000093",
                  }}
                  className="loaderIcon"
                />
              </div>
            )}
            <Form.Group
              style={{
                display: "Flex",
                margin: "2em",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  navigate(`/lookup?eventKey=${event.eventConfig.EventKey}`);
                }}
                style={{
                  justifyContent: "Center",
                  margin: "1",
                  backgroundColor: "#F5F5F5",
                  borderRadius: 4,
                  allignItems: "center",
                }}
              >
                <ArrowBackIcon style={{ color: "black" }} />
                <span
                  style={{
                    margin: "5px",
                    color: "#060606",
                    fontSize: "18px",
                    fontWeight: "Bold",
                  }}
                >
                  Go Back
                </span>
              </Button>
            </Form.Group>
          </Form>
        </Container>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SignIn></SignIn>
      </UnauthenticatedTemplate>
    </>
  );
};

export default StudentPreview;
