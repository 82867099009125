import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useEventContext } from "../../utils/EventContext";
import { useAuthContext } from "../../utils/AuthContext";
import { getStudentRecords } from "../../controllers/students";

import { Button } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box } from "@mui/material";
import Container from "react-bootstrap/Container";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import StudentsTable from "../../Components/StudentsTable";
import Header from "../../Components/Header";
import SignIn from "../../Components/SignIn";

const StudentDashboard = () => {
  const app = useAuthContext();
  const navigate = useNavigate();
  const event = useEventContext();

  useEffect(() => {
    app.initAuthSecurePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function saveTextAsFile() {
    getStudentRecords(event).then((response) => {
      if (response) {
        console.log();
      }
    });
  }

  return (
    <Container>
      <AuthenticatedTemplate>
        <Header></Header>
        <Button
          onClick={() => {
            navigate(`/lookup?eventKey=${event.eventConfig.EventKey}`);
          }}
          style={{
            margin: "1",
            backgroundColor: "#F5F5F5",
            borderRadius: 4,
          }}
        >
          <ArrowBackIcon style={{ color: "black" }} />
          <span
            style={{
              margin: "5px",
              color: "#060606",
            }}
          >
            Back
          </span>
        </Button>
        <t style={{ paddingRight: ".25em" }}></t>
        <Button
          onClick={() => {
            saveTextAsFile();
          }}
          style={{
            margin: "1",
            backgroundColor: "#F5F5F5",
            borderRadius: 4,
            color: "#060606",
          }}
        >
          Download
        </Button>
        <Box width="100%">
          <StudentsTable></StudentsTable>
        </Box>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SignIn></SignIn>
      </UnauthenticatedTemplate>
    </Container>
  );
};

export default StudentDashboard;
