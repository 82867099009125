import React, { useContext, createContext, useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
//import { getSharepointMembers } from "../../utils/EventConfigServerless";
//import { getRecruitmentLeads } from "../../utils/EventConfigServerless";

/**
 * Auth and roles
 * How you can enable roles in this app
 *
 * Roles: Admins and Recruitment Leads
 *
 * The "admins" are retrieved by looking at the membership of the sharepoint group
 * Currently, admins is a hardcoded list being returned by the backend.
 * We require the GroupMember.Read.All permission at an application level
 * (to avoid implenmting getting a MS graph token based on logged in user)
 *
 * Once that permission has been added in AAD,
 * uncomment the hardcoded getSharepointMembers() response in the app.js backend file.
 * Allow the function to make a call to retrieve the sharepoint members
 *
 * the "recruitment leads" are retreieved by looking at the list of jnj emails defined in the default event config
 * Only admins can view the /selectRecruitmentLeads page, where they can add or remove leads
 * Recruitment leads (or admins) can create and edit event configurations
 *
 * To enable these permissions within the app,
 * Uncomment all commented lines in this file
 * Additioinally, head to the SelectEvent component, and uncomment all commented lines
 * relating to admins and recruitment leads
 */

const appContext = createContext({
  accountName: "",
  accountEmail: "",
  signIn: () => {},
  getAccountName: () => {},
  getFullName: () => {},
  initAuthPublicPage: () => {},
  initAuthSecurePage: async () => {},
  signOut: () => {},
});

export function useAuthContext() {
  return useContext(appContext);
}

export default function ProvideAuthContext({ children }) {
  const auth = useProvideAuthContext();
  return <appContext.Provider value={auth}>{children}</appContext.Provider>;
}

function useProvideAuthContext() {
  const msal = useMsal();
  const [activeAccount, setActiveAccount] = useState(null);
  const [accountName, setAccountName] = useState(undefined);
  const [accountEmail, setAccountEmail] = useState(undefined);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  //const [recruitmentLeads, setRecruitmentLeads] = useState([]);
  //const [admins, setAdmins] = useState([]);
  //const [isAdmin, setIsAdmin] = useState(false);
  //const [isRecruitmentLead, setIsRecruitmentLead] = useState(false);

  /*useEffect(() => {
    console.log("Is Admin: ", isAdmin);
    console.log("Is Recruitment Lead: ", isRecruitmentLead);
  }, [isAdmin, isRecruitmentLead]);*/

  useEffect(() => {
    //Check if an active account has already been set
    if (activeAccount) {
      handleUserDetails(activeAccount);
    } else {
      try {
        //Otherwise, get the active account using MSAL
        const active = msal.instance.getActiveAccount();
        setActiveAccount(active);
        if (active) {
          console.log("Active acount: ", active);
          handleUserDetails(active);
        }
      } catch (error) {
        console.log("error in auth context: ", error);
      }
    }
    // eslint-disable-next-line
  }, [activeAccount, accountName, msal.instance]);

  /**
   * When an active account has been found, grab some more info about them such as name and email
   */
  const handleUserDetails = (user) => {
    setIsAuthenticated(true);
    setAccountName(user.name);
    setAccountEmail(user.username);

    //checkIfAdmin(user);
    //checkIfRecruitmentLead(user);
  };

  /*const checkIfAdmin = async (user) => {
    if (admins.length === 0) {
      const adminList = await getSharepointMembers();
      setAdmins(adminList);
    }
    if (admins && admins.includes(user.username.toLowerCase()))
      setIsAdmin(true);
  };

  const checkIfRecruitmentLead = async (user) => {
    if (recruitmentLeads.length === 0) {
      const leadsList = await getRecruitmentLeads();
      setRecruitmentLeads(leadsList);
    }
    console.log("username: ", user.username);
    if (
      recruitmentLeads &&
      recruitmentLeads.includes(user.username.toLowerCase())
    ) {
      setIsRecruitmentLead(true);
    }
  };*/

  const signIn = async () => {
    const accounts = msal.instance.getAllAccounts();
    if (accounts.length === 0) {
      //All sign ins are completing using redirect to login.microsoftonline.com rather than a microsoft login popup
      await msal.instance.loginRedirect();
    }
  };

  const initAuthSecurePage = async () => {
    await msal.instance
      .handleRedirectPromise()
      .then(async () => {
        // Check if user signed in

        //Upon completion of login done at the login.microsoftonline.com site, get the active account from MSAL and assing it to active account state
        const account = msal.instance.getActiveAccount();
        if (!account) {
          // redirect anonymous user to login page
          return await msal.instance.loginRedirect();
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return accountName;
  };

  const initAuthPublicPage = async () => {
    //Check on the current state of auth
    await msal.instance.handleRedirectPromise();
  };

  //Display logout popup upon sign out
  const signOut = async () => {
    await msal.instance.logoutPopup();
    setActiveAccount(null);
  };

  function getFullName() {
    let name = "";

    if (accountName) {
      name = accountName.substring(0, accountName.indexOf("["));
    }

    return name;
  }

  function getAccountName() {
    const active = msal.instance.getActiveAccount();
    if (accountName) {
      return accountName;
    }
    if (active && accountName === undefined) {
      console.log("Setting account name in redirect: ", active);
      setAccountName(active.name);
      setAccountEmail(active.username);
      return active.name;
    }

    return null;
  }

  return {
    activeAccount,
    accountName,
    accountEmail,
    isAuthenticated,
    //isAdmin,
    //isRecruitmentLead,
    signIn,
    getAccountName,
    getFullName,
    initAuthPublicPage,
    initAuthSecurePage,
    signOut,
  };
}
