import Accordion from "react-bootstrap/Accordion";
import React, { useState } from "react";
import { Typography, Box } from "@mui/material";
import { ExpandMore, ChevronRight } from "@mui/icons-material";
import { useEventContext } from "../utils/EventContext";

const FormResponse = (props) => {
  const [open, setOpen] = useState(false);
  const student = props.student;
  const event = useEventContext();

  //For each field, show if each question was required

  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <Box
            sx={{
              display: "flex",
              maxWidth: "425px",
            }}
            bottom="0px"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <ExpandMore
                sx={{
                  fontSize: "2.5rem",
                }}
              />
            ) : (
              <ChevronRight
                sx={{
                  fontSize: "2.5rem",
                  r: 1,
                }}
              />
            )}
            <Typography fontSize={"h5.fontSize"}>
              View Form Responses
            </Typography>
          </Box>{" "}
        </Accordion.Header>
        <Accordion.Body>
          <Typography
            component="div"
            style={{
              bottom: "1",
              backgroundColor: "#F5F5F5",
              borderRadius: 4,
              maxWidth: "425px",
              paddingBottom: "4px",
            }}
          >
            {event.eventConfig.NameVisible && (
              <div>
                <Box
                  sx={{
                    display: "flex",
                    fontSize: "h6.fontSize",
                    marginLeft: ".75em",
                    fontWeight: "Bold",
                  }}
                >
                  Name
                  {event.eventConfig.NameRequired && (
                    <div className="required">*</div>
                  )}
                </Box>
                <Box
                  sx={{ fontSize: "h6.fontSize", m: 1, marginBottom: "1em" }}
                >
                  {student.Name}
                </Box>
              </div>
            )}
            {event.eventConfig.EmailVisible && (
              <div>
                <Box
                  sx={{
                    display: "flex",
                    fontSize: "h6.fontSize",
                    marginLeft: ".75em",
                    fontWeight: "Bold",
                  }}
                >
                  Email{" "}
                  {event.eventConfig.EmailRequired && (
                    <div className="required">*</div>
                  )}
                </Box>
                <Box sx={{ fontSize: "h6.fontSize", m: 1 }}>
                  {student.Email}
                </Box>
              </div>
            )}
            {event.eventConfig.PhoneNumberVisible && (
              <div>
                <Box
                  sx={{
                    display: "flex",
                    fontSize: "h6.fontSize",
                    marginLeft: ".75em",
                    marginBottom: ".25em",
                    fontWeight: "Bold",
                  }}
                >
                  Phone #{" "}
                  {event.eventConfig.PhoneNumberRequired && (
                    <div className="required">*</div>
                  )}
                </Box>
                <Box sx={{ fontSize: "h6.fontSize", m: 1 }}>
                  {student.PhoneNumber}
                </Box>
              </div>
            )}
            {event.eventConfig.SchoolVisible && (
              <div>
                <Box
                  sx={{
                    display: "flex",
                    fontSize: "h6.fontSize",
                    marginLeft: ".75em",
                    fontWeight: "Bold",
                  }}
                >
                  School{" "}
                  {event.eventConfig.SchoolRequiredRequired && (
                    <div className="required">*</div>
                  )}
                </Box>
                <Box sx={{ fontSize: "h6.fontSize", m: 1 }}>
                  {student.School && student.School}
                </Box>
              </div>
            )}
            {event.eventConfig.GradYearVisible && (
              <div>
                <Box
                  sx={{
                    display: "flex",
                    fontSize: "h6.fontSize",
                    marginLeft: ".75em",
                    fontWeight: "Bold",
                  }}
                >
                  Grad Date{" "}
                  {event.eventConfig.GradYearRequired && (
                    <div className="required">*</div>
                  )}
                </Box>
                <Box sx={{ fontSize: "h6.fontSize", m: 1 }}>
                  {student.GradMonth} {student.GradYear}
                </Box>
              </div>
            )}

            {event.eventConfig.MajorVisible && (
              <div>
                <Box
                  sx={{
                    display: "flex",
                    fontSize: "h6.fontSize",
                    marginLeft: ".75em",
                    fontWeight: "Bold",
                  }}
                >
                  Major{" "}
                  {event.eventConfig.MajorRequired && (
                    <div className="required">*</div>
                  )}
                </Box>
                <Box sx={{ fontSize: "h6.fontSize", m: 1 }}>
                  {student.Major && student.Major.join(", ")}
                </Box>
              </div>
            )}
            {event.eventConfig.GPAVisible && (
              <div>
                <Box
                  sx={{
                    display: "flex",
                    fontSize: "h6.fontSize",
                    marginLeft: ".75em",
                    fontWeight: "Bold",
                  }}
                >
                  GPA
                  {event.eventConfig.GPARequired && (
                    <div className="required">*</div>
                  )}
                </Box>
                <Box sx={{ fontSize: "h6.fontSize", m: 1 }}>{student.GPA}</Box>
              </div>
            )}

            {event.eventConfig.PreviouslyJJVisible && (
              <div>
                <Box
                  sx={{
                    display: "flex",
                    fontSize: "h6.fontSize",
                    marginLeft: ".75em",
                    fontWeight: "Bold",
                  }}
                >
                  Previously J&J
                  {event.eventConfig.PreviouslyJJRequired && (
                    <div className="required">*</div>
                  )}
                </Box>
                {student.PreviouslyJJ !== undefined && (
                  <Box sx={{ fontSize: "h6.fontSize", m: 1 }}>
                    {student.PreviouslyJJ ? "Yes" : "No"}
                  </Box>
                )}
              </div>
            )}
            {event.eventConfig.SponsorshipVisible && (
              <div>
                <Box
                  sx={{
                    display: "flex",
                    fontSize: "h6.fontSize",
                    marginLeft: ".75em",
                    fontWeight: "Bold",
                  }}
                >
                  Sponsorship
                  {event.eventConfig.SponsorshipRequired && (
                    <div className="required">*</div>
                  )}
                </Box>
                {student.Sponsorship !== undefined && (
                  <Box sx={{ fontSize: "h6.fontSize", m: 1 }}>
                    {student.Sponsorship ? "Yes" : "No"}
                  </Box>
                )}
              </div>
            )}
            {event.eventConfig.RelocationVisible && (
              <div>
                <Box
                  sx={{
                    display: "flex",
                    fontSize: "h6.fontSize",
                    marginLeft: ".75em",
                    fontWeight: "Bold",
                  }}
                >
                  Relocate?
                  {event.eventConfig.RelocationRequired && (
                    <div className="required">*</div>
                  )}
                </Box>
                {student.Relocation !== undefined && (
                  <Box sx={{ fontSize: "h6.fontSize", m: 1 }}>
                    {student.Relocation ? "Yes" : "No"}
                  </Box>
                )}
              </div>
            )}
            {event.eventConfig.RoleInterestVisible && (
              <div>
                <Box
                  sx={{
                    display: "flex",
                    fontSize: "h6.fontSize",
                    marginLeft: ".75em",
                    fontWeight: "Bold",
                  }}
                >
                  Role
                  {event.eventConfig.RoleInterestRequired && (
                    <div className="required">*</div>
                  )}
                </Box>
                <Box sx={{ fontSize: "h6.fontSize", m: 1 }}>
                  {student.RoleInterest}
                </Box>
              </div>
            )}

            {event.eventConfig.CapabilityInterestVisible && (
              <div>
                <Box
                  sx={{
                    display: "flex",
                    fontSize: "h6.fontSize",
                    marginLeft: ".75em",
                    fontWeight: "Bold",
                  }}
                >
                  Capability
                  {event.eventConfig.CapabilityInterestRequired && (
                    <div className="required">*</div>
                  )}
                </Box>
                <Box sx={{ fontSize: "h6.fontSize", m: 1 }}>
                  {student.CapabilityInterest &&
                    student.CapabilityInterest.join(", ")}
                </Box>
              </div>
            )}
          </Typography>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default FormResponse;
