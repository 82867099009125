import React, { useState } from "react";
import { Form, Container } from "react-bootstrap";
import Header from "../../Components/Header";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  AddEventConfig,
  getDefaultEventConfig,
  getEventConfigByKey,
  updateEventConfig,
} from "../../controllers/event";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEventContext } from "../../utils/EventContext";
import { fieldNames } from "../../data/fieldNames";
import { FaSync } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import CreatableSelect from "react-select/creatable";

const FieldConfigurator = () => {
  const { state } = useLocation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const eventContext = useEventContext();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [configurableEvent, setConfigurableEvent] = useState({
    EventName: "",
    EventDate: "",
    Links: {},
    Tags: [],
    Schools: [],
    Majors: [],
    Roles: [],
    Capabilities: [],
    Years: [],
  });
  const [columnOptions, setColumnOptions] = useState({
    tags: [],
    schools: [],
    majors: [],
    roles: [],
    links: {},
    capabilities: [],
    years: [],
  });

  const validationErrors = {
    EventName: "Please enter event name.",
    EventDate: "Please enter event date",
    Tags: "Please enter the tags you want to add to students during the recruitment process (Interviewed, Top Candidate, Keep Warm)",
    Schools: "Please enter schools that students will choose from",
    Majors: "Please enter majors that students will choose from",
    Roles: "Please enter roles that students will choose from",
    Capabilities: "Please enter capabilities that students will choose from",
    Years: "Please enter graduation years that students will choose from",
  };

  /**
   * Provide the recruiter links based on the key for this event
   */
  const recruiterLinks = () => {
    const base_url = "https://www.jjtcampusrecruiting.jnj.com";
    const recruiter_path = "/recruiter";
    const event_query_param = `?eventKey=${configurableEvent.EventKey}`;

    const student_form_url = base_url + event_query_param;
    const recruiter_url = base_url + recruiter_path + event_query_param;

    const links = { student: student_form_url, recruiter: recruiter_url };
    return links;
  };

  const isFormValid = () => {
    let newErrors = {};

    if (!configurableEvent.EventName || !configurableEvent.EventName === "")
      newErrors.EventName = validationErrors.EventName;
    if (!configurableEvent.EventDate || configurableEvent.Date === "")
      newErrors.EventDate = validationErrors.EventDate;
    if (configurableEvent.Tags && configurableEvent.Tags.length === 0)
      newErrors.Tags = validationErrors.Tags;
    if (
      configurableEvent.SchoolVisible &&
      configurableEvent.Schools &&
      configurableEvent.Schools.length === 0
    )
      newErrors.Schools = validationErrors.Schools;
    if (
      configurableEvent.MajorVisible &&
      configurableEvent.Majors &&
      configurableEvent.Majors.length === 0
    )
      newErrors.Majors = validationErrors.Majors;
    if (
      configurableEvent.RoleInterestVisible &&
      configurableEvent.Roles &&
      configurableEvent.Roles.length === 0
    )
      newErrors.Roles = validationErrors.Roles;
    if (
      configurableEvent.CapabilityInterestVisible &&
      configurableEvent.Capabilities &&
      configurableEvent.Capabilities.length === 0
    )
      newErrors.Capabilities = validationErrors.Capabilities;

    if (Object.keys(newErrors).length > 0) {
      setFormErrors(newErrors);
      return false;
    } else {
      return true;
    }
  };

  const prepareLinksForConfiguration = (links) => {
    try {
      if (links && Array.isArray(links)) {
        return links.reduce((acc, curr) => {
          const [key, ...rest] = curr.split(":");
          const value = rest.join(":");
          acc[key.trim()] = value.trim();
          return acc;
        }, {});
      } else if (typeof links === "object") {
        console.log("links: ", links);
        return links;
      }
    } catch (err) {
      console.log("error parsing links");
    }

    return {};
  };

  const prepareLinksForSubmission = (links, roles) => {
    return Object.entries(links)
      .filter(([key]) => roles.includes(key))
      .map(([key, value]) => `${key}:${value}`);
  };

  const prepEventDataForConfiguration = (event) => {
    event.Links = prepareLinksForConfiguration(event.Links);
    return event;
  };

  const prepEventDataForSubmission = (event) => {
    const allowedFields = Object.keys(eventContext.baseEventConfig);
    const configData = Object.keys(event)
      .filter((key) => allowedFields.includes(key))
      .reduce((obj, key) => {
        obj[key] = configurableEvent[key];
        return obj;
      }, {});

    configData.Links = prepareLinksForSubmission(event.Links, event.Roles);
    configData.Title = event.EventName;
    delete configData.id;

    return configData;
  };

  useEffect(() => {
    //If editing an existig event, assign the state based on what was passed when user hit the edit button
    if (state) {
      const eventConfig = prepEventDataForConfiguration(state);
      setConfigurableEvent(eventConfig);
    }
    //If no event data was passed, grab the event by event key
    else if (!state && queryParams.get("eventKey")) {
      getEventConfigByKey(queryParams.get("eventKey")).then((eventConfig) => {
        if (
          eventConfig &&
          eventConfig.EventKey === queryParams.get("eventKey")
        ) {
          eventConfig = prepEventDataForConfiguration(eventConfig);
          setConfigurableEvent(eventConfig);
        }
      });
    } else {
      //Otherwise return to select event page
      navigate("/selectEvent");
    }

    getColumnOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getColumnOptions = async () => {
    let defaultEventConfig = await getDefaultEventConfig();

    if (defaultEventConfig) {
      let tagOptions = defaultEventConfig.Tags.map((tag) => {
        return { label: tag, value: tag };
      });
      let schoolOptions = defaultEventConfig.Schools.map((school) => {
        return { label: school, value: school };
      });
      let majorOptions = defaultEventConfig.Majors.map((major) => {
        return { label: major, value: major };
      });
      let roleOptions = defaultEventConfig.Roles.map((role) => {
        return { label: role, value: role };
      });
      let capabilityOptions = defaultEventConfig.Capabilities.map(
        (capability) => {
          return { label: capability, value: capability };
        }
      );

      let yearOptions = defaultEventConfig.Years.map((year) => {
        return { label: year, value: year };
      });

      const colOptions = {
        tags: tagOptions,
        schools: schoolOptions,
        majors: majorOptions,
        roles: roleOptions,
        capabilities: capabilityOptions,
        years: yearOptions,
      };

      setColumnOptions(colOptions);
    }
  };

  const handleToggleChange = (fieldName, type) => {
    let key = `${fieldName}${type}`;
    let currentState = configurableEvent[key];

    if (type === "Required" && !currentState) {
      let visibleKey = `${fieldName}Visible`;
      setConfigurableEvent({
        ...configurableEvent,
        [key]: true,
        [visibleKey]: true,
      });
    } else if (type === "Visible" && currentState) {
      let requiredKey = `${fieldName}Required`;
      setConfigurableEvent({
        ...configurableEvent,
        [key]: false,
        [requiredKey]: false,
      });
    } else {
      setConfigurableEvent({
        ...configurableEvent,
        [key]: !currentState,
      });
    }
  };

  function checkErrorsAfterUpdate(name) {
    if (!!formErrors[name]) {
      const newErrors = formErrors;
      delete newErrors[name];
      setFormErrors(newErrors);
    }
  }

  function setField(name, value) {
    setConfigurableEvent({
      ...configurableEvent,
      [name]: value,
    });

    checkErrorsAfterUpdate(name);
  }

  function handleChange(event) {
    const { target } = event;
    const value = target.value;
    const { name } = target;
    setField(name, value);
  }

  function handleMultiSelectDropdownChange(name, event) {
    let values = event.map((e) => {
      return e.value;
    });

    setField(name, values);
  }

  function handleLinkChange(role, link) {
    setField("Links", {
      ...configurableEvent.Links,
      [role]: link,
    });
  }

  function handleDateChange(event) {
    const { target } = event;
    const value = target.value;
    const { name } = target;
    if (value) {
      setField(name, value);
    }
  }

  async function submitForm(e) {
    e.preventDefault();

    const configData = prepEventDataForSubmission(configurableEvent);

    if (isFormValid()) {
      if (configurableEvent.id) {
        saveExistingEventConfig(configData);
      } else {
        submitNewEventConfig(configData);
      }
    }
  }

  async function submitNewEventConfig(newConfig) {
    setIsSubmitting(true);
    const eventKey =
      configurableEvent.EventName.replaceAll(" ", "") +
      configurableEvent.EventDate;

    AddEventConfig(newConfig, eventKey)
      .then((eventConfig) => {
        setIsSubmitting(false);
        if (eventConfig) {
          eventContext.setEventConfig(eventConfig);
          navigate("/selectEvent");
        } else {
          throw Error("The event config was not created successfully");
        }
      })
      .catch((error) => {
        navigate("/createEventError");
      });
  }

  async function saveExistingEventConfig(existingConfig) {
    setIsSubmitting(true);

    updateEventConfig(existingConfig, configurableEvent.id)
      .then((eventConfig) => {
        setIsSubmitting(false);

        if (eventConfig) {
          eventContext.setEventConfig(eventConfig);
          navigate("/selectEvent");
        } else {
          throw Error("The event config was not updated successfully");
        }
      })
      .catch((error) => {
        console.log("Error updating event config: ", error);
        navigate("/updateEventError");
      });
  }

  return (
    configurableEvent && (
      <Container className="Student">
        <Header></Header>
        <Form className="Form" onSubmit={(e) => submitForm(e)}>
          <h3 className="text-center mt-3">Configure your Event</h3>
          <Button
            onClick={() => navigate(-1)}
            style={{
              margin: "1",
              backgroundColor: "#F5F5F5",
              borderRadius: 4,
            }}
          >
            <ArrowBackIcon style={{ color: "black" }} />
            <span
              style={{
                margin: "5px",
                color: "#060606",
              }}
            >
              Back
            </span>
          </Button>
          {configurableEvent.id && (
            <>
              <Form.Group>
                <h4 className="mt-3">{configurableEvent.EventName}</h4>
              </Form.Group>
              <Form.Group>
                <h6>
                  <span style={{ fontWeight: "bold" }}> Student URL: </span>
                  <Link to={recruiterLinks().student}>
                    {recruiterLinks().student}
                  </Link>
                </h6>
                <h6>
                  <span style={{ fontWeight: "bold" }}> Recruiter URL: </span>
                  <Link to={recruiterLinks().recruiter}>
                    {recruiterLinks().recruiter}
                  </Link>
                </h6>
                <h6>
                  <span style={{ fontWeight: "bold" }}> Event Key: </span>
                  {eventContext.eventConfig.EventKey}
                </h6>
              </Form.Group>
            </>
          )}
          <Form.Group>
            <Form.Label htmlFor="name" style={{ display: "flex" }}>
              Event Name <div className="required">*</div>
            </Form.Label>
            <Form.Control
              type="text"
              name="EventName"
              id="name"
              placeholder="Name your event..."
              style={{ backgroundColor: "#f1f1f1" }}
              value={configurableEvent.EventName}
              onChange={(e) => handleChange(e)}
            />
            <div className="red">{formErrors.EventName}</div>
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="EventDate" style={{ display: "flex" }}>
              Event Date (mm/dd/yyyy) <div className="required">*</div>
            </Form.Label>
            <Form.Control
              type="date"
              name="EventDate"
              id="EventDate"
              //placeholder="mm/dd/yyyy"
              style={{ backgroundColor: "#f1f1f1" }}
              value={configurableEvent.EventDate}
              onChange={(e) => handleDateChange(e)}
            />
            <div className="red">{formErrors.EventDate}</div>
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="EventDescription">
              Event Description
            </Form.Label>
            <Form.Control
              type="text"
              name="Description"
              id="Description"
              placeholder="Enter your quick event description (eg. Fall 2023)"
              style={{ backgroundColor: "#f1f1f1" }}
              value={configurableEvent.Description}
              onChange={(e) => handleChange(e)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="Tags">Tags</Form.Label>
            <CreatableSelect
              type="select"
              placeholder={"Select..."}
              name="Tags"
              id="Tags"
              options={columnOptions.tags}
              isMulti
              value={
                configurableEvent.Tags
                  ? configurableEvent.Tags.map((tag) => {
                      return { value: tag, label: tag };
                    })
                  : ""
              }
              onChange={(e) => handleMultiSelectDropdownChange("Tags", e)}
            />
            <div className="red">{formErrors.Tags}</div>
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="School">Schools</Form.Label>
            <CreatableSelect
              type="select"
              placeholder={"Select..."}
              name="Schools"
              id="Schools"
              options={columnOptions.schools}
              isMulti
              value={
                configurableEvent.Schools
                  ? configurableEvent.Schools.map((tag) => {
                      return { value: tag, label: tag };
                    })
                  : ""
              }
              onChange={(e) => handleMultiSelectDropdownChange("Schools", e)}
            />
            <div className="red">{formErrors.Schools}</div>
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="Years">Graduation Years</Form.Label>
            <CreatableSelect
              type="select"
              placeholder={"Select..."}
              name="Years"
              id="Years"
              options={columnOptions.years}
              isMulti
              value={
                configurableEvent.Years
                  ? configurableEvent.Years.map((tag) => {
                      return { value: tag, label: tag };
                    })
                  : ""
              }
              onChange={(e) => handleMultiSelectDropdownChange("Years", e)}
            />
            <div className="red">{formErrors.Years}</div>
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="Major">Majors</Form.Label>
            <CreatableSelect
              type="select"
              placeholder={"Select..."}
              name="Major"
              id="Major"
              options={columnOptions.majors}
              isMulti
              value={
                configurableEvent.Majors
                  ? configurableEvent.Majors.map((tag) => {
                      return { value: tag, label: tag };
                    })
                  : ""
              }
              onChange={(e) => handleMultiSelectDropdownChange("Majors", e)}
            />
            <div className="red">{formErrors.Majors}</div>
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="roleInterest">Roles</Form.Label>
            <CreatableSelect
              type="select"
              placeholder={"Select..."}
              name="roleInterest"
              id="roleInterest"
              options={columnOptions.roles}
              isMulti
              value={
                configurableEvent.Roles
                  ? configurableEvent.Roles.map((tag) => {
                      return { value: tag, label: tag };
                    })
                  : ""
              }
              onChange={(e) => handleMultiSelectDropdownChange("Roles", e)}
            />
            <div className="red">{formErrors.Roles}</div>
            {configurableEvent.Roles && configurableEvent.Roles.length > 0 && (
              <Form.Group>
                <>
                  <Form.Label>Application Links</Form.Label>
                  <p>
                    Please provide the link for the application for each
                    selected role.
                  </p>
                  {configurableEvent.Roles.map((role) => (
                    <Form.Group
                      key={role}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Form.Label style={{ marginRight: "8px" }}>
                        {role}:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={`Enter link for ${role} role`}
                        value={
                          configurableEvent.Links[role]
                            ? configurableEvent.Links[role]
                            : ""
                        }
                        onChange={(e) => handleLinkChange(role, e.target.value)}
                      />
                    </Form.Group>
                  ))}
                </>
              </Form.Group>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="capabilityInterest">Capabilities</Form.Label>
            <CreatableSelect
              type="select"
              placeholder={"Select..."}
              name="capabilityInterest"
              id="capabilityInterest"
              options={columnOptions.capabilities}
              isMulti
              value={
                configurableEvent.Capabilities
                  ? configurableEvent.Capabilities.map((tag) => {
                      return { value: tag, label: tag };
                    })
                  : ""
              }
              onChange={(e) =>
                handleMultiSelectDropdownChange("Capabilities", e)
              }
            />
            <div className="red">{formErrors.Capabilities}</div>
          </Form.Group>
          {fieldNames.map((fieldName) => (
            <Form.Group key={fieldName}>
              <Form.Group>
                <Form.Label>{fieldName}</Form.Label>
              </Form.Group>
              <Form.Group>
                <Form.Switch
                  id={`${fieldName}-visibility-switch`}
                  label="Visible"
                  checked={configurableEvent[`${fieldName}Visible`]}
                  onChange={() => handleToggleChange(fieldName, "Visible")}
                />
              </Form.Group>
              <Form.Group>
                <Form.Switch
                  id={`${fieldName}-required-switch`}
                  label="Required"
                  checked={configurableEvent[`${fieldName}Required`]}
                  onChange={() => handleToggleChange(fieldName, "Required")}
                  style={{ fontWeight: 300 }}
                />
              </Form.Group>
            </Form.Group>
          ))}
          <Form.Group
            style={{
              display: "Flex",
              alignItems: "center",
            }}
          >
            <Button
              type="submit"
              disabled={isSubmitting}
              style={{
                height: "50px",
                width: "100px",
                borderRadius: 4,
                backgroundColor: "#c8132e",
                color: "#ffffff",
                borderColor: "#c8132e",
                marginTop: "1em",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { md: "22px", sm: "18px", xs: "18px" },
                }}
              >
                {configurableEvent.EventKey ? "Save" : "Submit"}
              </Typography>
            </Button>
            {isSubmitting && (
              <FaSync
                style={{
                  fontSize: "24px",
                  color: "#000093",
                  marginLeft: ".5em",
                }}
                className="loaderIcon"
              />
            )}
          </Form.Group>
        </Form>
      </Container>
    )
  );
};

export default FieldConfigurator;
