import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../Components/Header";
import { Form, Button, Container } from "react-bootstrap";
import Select from "react-select";
import { Typography } from "@mui/material";
import {
  uploadResume,
  AppendSharepointList,
  updateSharepointRecord,
} from "../../controllers/students";
import { useNavigate, useLocation } from "react-router";
import { useAuthContext } from "../../utils/AuthContext";
import { FaSync } from "react-icons/fa";
import { useEventContext } from "../../utils/EventContext";
import { getEventConfigByKey } from "../../controllers/event";

const monthOptions = [
  { label: "January", value: "January" },
  { label: "February", value: "February" },
  { label: "March", value: "March" },
  { label: "April", value: "April" },
  { label: "May", value: "May" },
  { label: "June", value: "June" },
  { label: "July", value: "July" },
  { label: "August", value: "August" },
  { label: "September", value: "September" },
  { label: "October", value: "October" },
  { label: "November", value: "November" },
  { label: "December", value: "December" },
];

const StudentForm = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const editPath = "/student/edit";
  const path = location.pathname;
  const queryParams = new URLSearchParams(location.search);

  const app = useAuthContext();

  const [formErrors, setFormErrors] = useState({});
  const [mainErrorMessage, setMainErrorMessage] = useState("");

  const [otherMajor, setOtherMajor] = useState("");
  const [otherSchool, setOtherSchool] = useState("");
  const [resumeFileData, setResumeFileData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [studentUpdates, setStudentUpdates] = useState({});
  const [recruiter, setRecruiter] = useState(null);
  const [applicationLinks, setApplicationLinks] = useState({});

  const event = useEventContext();

  const { state } = useLocation();

  const [student, setStudent] = useState({
    EventKey: "",
    Name: "",
    Email: "",
    PhoneNumber: "",
    School: "",
    GradMonth: "",
    GradYear: "",
    Major: [],
    GPA: null,
    Sponsorship: null,
    PreviouslyJJ: null,
    Relocation: null,
    RoleInterest: "",
    CapabilityInterest: [],
    Resume: "",
    ResumeFileName: "",
    Protection: null,
    Rating: 0,
    Tags: [],
    Notes: "",
    PrimaryRecruiter: "",
    SubmissionTime: "",
    NotesHistory: [],
  });

  const validationErrors = {
    Name: "Please enter your name.",
    Email: "Please enter your email",
    PhoneNumber: "Please enter your phone number",
    School: "Please select your school or university.",
    GradMonth: "Please select your graduation month",
    GradYear: "Please enter your graduation month",
    GPA: "Please enter a valid GPA. ",
    Sponsorship:
      "Please select wether you are currently on a Visa to attend school, such as an H1-B, or OPT/CPT Visa?",
    PreviouslyJJ:
      "Please select whether or not you have worked at J&J previously",
    Relocation: "Please select whether or not you are willing to relocate.",
    RoleInterest: "Please select what type of role you are interested in",
    CapabilityInterest: "Please select what capability you are interested in",
    Major: "Please select your major",
    Resume: "Please upload a copy of your resume",
    Protection:
      "Please acknowledge that you have read the privacy policy agreement",
  };

  function prepareApplicationLinks(links) {
    let appLinks = {};

    try {
      if (links && Array.isArray(links)) {
        appLinks = links.reduce((acc, curr) => {
          const [key, ...rest] = curr.split(":");
          const value = rest.join(":");
          acc[key.trim()] = value.trim();
          return acc;
        }, {});
      } else if (typeof links === "object") {
        appLinks = links;
      }
    } catch (err) {
      appLinks = {};
    }

    setApplicationLinks(appLinks);
  }

  function retrieveLinksByRole(links, roles) {
    const linksByRole = Object.fromEntries(
      Object.entries(links).filter(
        ([key, value]) => roles.includes(key) && links[key]
      )
    );

    return linksByRole;
  }

  async function determineEventConfiguration() {
    //Check if an event config is already in context
    if (event.eventConfig.id) {
      prepareApplicationLinks(event.eventConfig.Links);
    }
    //otherwise check if an eventKey was supplied in the url
    else if (
      queryParams.get("eventKey") &&
      queryParams.get("eventKey") !== "null"
    ) {
      //Get the event config from sharepoint
      const sharepointEventConfig = await getEventConfigByKey(
        queryParams.get("eventKey")
      );
      console.log("Sharepoint event config: ", sharepointEventConfig);
      if (sharepointEventConfig) {
        event.setEventConfig(sharepointEventConfig);
        prepareApplicationLinks(sharepointEventConfig.Links);
      }
    } else {
      //If no event in context and no event key provided,
      //navigate to student event selector where students can enter an event key
      navigate("/studentEventSelector");
    }
  }

  useEffect(() => {
    app.initAuthPublicPage();
    determineEventConfiguration();

    //Require a J&J login if the current path is for editing a student profile
    if (path === editPath && state && state.Name) {
      app.initAuthSecurePage();
      if (app.accountName) {
        setStudent(state);
        setRecruiter(app.getFullName());
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.accountName]);

  function setField(name, value) {
    if (student.id) {
      setStudentUpdates({
        ...studentUpdates,
        [name]: value,
      });
    }

    setStudent({
      ...student,
      [name]: value,
    });

    //Upon every update of student data, reset the error message for that respective field
    //Validate will take place again upon form submission, and may replace that error
    checkErrorsAfterUpdate(name);
  }

  function checkErrorsAfterUpdate(name) {
    if (!!formErrors[name]) {
      const newErrors = formErrors;
      delete newErrors[name];
      setFormErrors(newErrors);
    }

    if (mainErrorMessage && Object.keys(formErrors).length === 0)
      setMainErrorMessage("");
  }

  function setFormErrorsMessage(formErrors) {
    if (Object.keys(formErrors).length > 0)
      setMainErrorMessage(
        "Error: You missed a required field, please see above."
      );
    else setMainErrorMessage("");

    setFormErrors(formErrors);
  }

  function handleChange(event) {
    const { target } = event;
    const value = target.value;
    const { name } = target;
    setField(name, value);
  }

  function handleYesNoRadioChange(event) {
    const { target } = event;
    const value =
      target.value === "Yes" || target.value === "on" ? true : false;
    const { name } = target;
    setField(name, value);
  }

  function handleOtherMajorChange(event) {
    setOtherMajor(event.target.value);
    checkErrorsAfterUpdate("Major");
  }

  function handleOtherSchoolChange(event) {
    setOtherSchool(event.target.value);
    checkErrorsAfterUpdate("School");
  }

  function handleDropdownChange(name, value) {
    setField(name, value);
  }

  function handleMultiSelectDropdownChange(name, event) {
    let values = event.map((e) => {
      return e.value;
    });

    setField(name, values);
  }

  /**
   * Grab the file that a candidate uploaded and assign it to the candidate data
   */
  function handleFileUploadChange(event) {
    if (event.target.files.length) {
      const resumeFile = event.target.files[0];
      const resumeFileName = resumeFile.name;

      //Remove characers that are not valid for a sharepoint file name

      const updatedFileName = resumeFileName.replace(
        /[&\\#,+()$~%'":*?<>{}]/g,
        ""
      );
      setResumeFileData(resumeFile);
      setField("ResumeFileName", updatedFileName);
    } else {
      setResumeFileData(null);
      setField("ResumeFileName", null);
    }
  }

  function handleMultiselectChange(event) {
    const { target } = event;
    const { name } = target;
    const value = target.value;
    let arr = student[name];

    if (arr.includes(value)) arr.splice(arr.indexOf(value), 1);
    else arr.push(value);

    setField(name, arr);
  }

  function isFormValidEventConfig() {
    let newErrors = {};
    let customValidationFields = [
      "Major",
      "GPA",
      "Major",
      "School",
      "CapabilityIntersted",
      "Resume",
      "Protection",
    ];

    //standard validation
    //Iterate through each field. If it is required, display error if value was not entered by candidate
    Object.keys(validationErrors).forEach((field) => {
      if (customValidationFields.includes(field)) {
      } else if (
        event.eventConfig[`${field}Visible`] &&
        event.eventConfig[`${field}Required`] &&
        (student[field] === null ||
          student[field] === undefined ||
          student[field] === "")
      )
        newErrors[field] = validationErrors[field];
    });

    //custom validations for non-standard fields
    if (
      event.eventConfig.MajorVisible &&
      event.eventConfig.MajorRequired &&
      (!student.Major ||
        student.Major.length === 0 ||
        (student.Major.includes("Other") &&
          otherMajor === "" &&
          student.Major.length === 1))
    )
      newErrors.Major = validationErrors.Major;
    if (
      event.eventConfig.SchoolVisible &&
      event.eventConfig.SchoolRequired &&
      (!student.School ||
        student.School === "" ||
        (student.School === "Other" && otherSchool === ""))
    )
      newErrors.School = validationErrors.School;
    if (
      event.eventConfig.GPAVisible &&
      student.GPA &&
      (student.GPA < 0 || student.GPA > 4)
    )
      newErrors.GPA = validationErrors.GPA;
    if (
      event.eventConfig.CapabilityInterestVisible &&
      event.eventConfig.CapabilityInterestRequired &&
      (!student.CapabilityInterest || student.CapabilityInterest.length === 0)
    )
      newErrors.CapabilityInterest = validationErrors.CapabilityInterest;
    if (
      event.eventConfig.ResumeVisible &&
      event.eventConfig.ResumeRequired &&
      !resumeFileData &&
      !student.Resume
    )
      newErrors.ResumeFileName = validationErrors.Resume;
    if (!student.Protection) newErrors.Protection = validationErrors.Protection;

    if (Object.keys(newErrors).length > 0) {
      setFormErrorsMessage(newErrors);
      return false;
    } else {
      return true;
    }
  }

  /*
   * For fields where a candidate can enter the option "Other", add the freeform value they entered from the custom input box that appeared
   */
  function handleOtherFields(data) {
    let majorArr = student.Major;
    let updatedStudent = data;

    if (otherMajor !== "") {
      majorArr.push(otherMajor);
      updatedStudent = { ...updatedStudent, Major: majorArr };
    }
    if (otherSchool !== "") {
      updatedStudent = { ...updatedStudent, School: otherSchool };
    }

    return updatedStudent;
  }

  async function submitForm(e) {
    e.preventDefault();

    console.log("Submission: ", student);

    //First validate form responses. Only continue if valid
    if (isFormValidEventConfig()) {
      setIsSubmitting(true);

      //If a file is provided, upload thier resume to sharepoint
      //and grab the respective url for that resume and assign it to that candiaate
      try {
        let resumeLink = null;

        if (resumeFileData) {
          resumeLink = await uploadResume(
            resumeFileData,
            student.ResumeFileName
          );
        }

        //If the student has an ID, we will update thier exisitng registration
        if (student.id) {
          let graphUpdateData = {
            ...studentUpdates,
            NotesHistory: student.NotesHistory,
          };

          if (otherMajor || otherSchool)
            graphUpdateData = handleOtherFields(graphUpdateData);
          if (resumeLink)
            graphUpdateData = { ...graphUpdateData, Resume: resumeLink };

          //Send only the data we plan to update
          try {
            updateSharepointRecord(graphUpdateData, student.id, recruiter).then(
              () => {
                setIsSubmitting(false);
                navigate(
                  //upon completion of update, head back to recruiter view
                  `/student?eventKey=${event.eventConfig.EventKey}&student=${student.id}`
                );
              }
            );
          } catch (error) {
            console.error("Update registration Error: ", error);
            navigate("/updateRegistrationError");
            setIsSubmitting(false);
          }
        }
        //if this student does not have an ID yet, we are submitting a new registration
        else {
          try {
            let studentData = student;

            if (otherMajor || otherSchool)
              studentData = handleOtherFields(studentData);

            //Post a new registration for this candidate
            const registration = await AppendSharepointList(
              studentData,
              resumeLink,
              event.eventConfig.EventKey
            );

            if (registration) {
              //upon successful upload of registration, head to thank you page
              const linksByRole = retrieveLinksByRole(applicationLinks, [
                studentData.RoleInterest,
              ]);

              navigate("/thanks", { state: linksByRole });
              setIsSubmitting(false);
            }
          } catch (registrationErr) {
            console.error("Registration Error: ", registrationErr);
            navigate("/createRegistrationError");
            setIsSubmitting(false);
          }
        }
      } catch (resumeErr) {
        console.error("Resume upload Error: ", resumeErr);
        navigate("/uploadResumeError");
        setIsSubmitting(false);
      }
    }
  }

  return (
    <Container className="Student">
      <Header></Header>
      <Form className="Form" onSubmit={(e) => submitForm(e)}>
        <Form.Label htmlFor="protection">
          <div className="labelOverride">Data protection</div>
        </Form.Label>
        <p style={{ fontSize: 12 }}>
          By registering, you consent to the collection and use of your
          information for recruitment, management, and planning purposes. We may
          allow you to register and log into your platform account with your
          social media account. With your consent, certain information may be
          shared by your social media account, such as name, education, and work
          experience. You may withdraw your consent at any time. Please read our
          Privacy Policy.
        </p>
        {path === editPath && <h4>{`Editing ${student.Name}'s response`}</h4>}
        {event.eventConfig.NameVisible && (
          <Form.Group>
            <Form.Label htmlFor="name" style={{ display: "flex" }}>
              <div className="labelOverride">Full Name</div>
              {event.eventConfig.NameRequired && (
                <div className="required">*</div>
              )}
            </Form.Label>
            <Form.Control
              type="text"
              name="Name"
              id="name"
              placeholder="Full Name"
              value={student.Name}
              onChange={(e) => handleChange(e)}
            />
            <div className="red">{formErrors.Name}</div>
          </Form.Group>
        )}
        {event.eventConfig.EmailVisible && (
          <Form.Group>
            <Form.Label htmlFor="email" style={{ display: "flex" }}>
              <div className="labelOverride">Email Address</div>
              {event.eventConfig.EmailRequired && (
                <div className="required">*</div>
              )}
            </Form.Label>
            <Form.Control
              type="email"
              name="Email"
              id="email"
              placeholder="examples@example.com"
              value={student.Email}
              onChange={(e) => handleChange(e)}
            />
            <div className="red">{formErrors.Email}</div>
          </Form.Group>
        )}
        {event.eventConfig.PhoneNumberVisible && (
          <Form.Group>
            <Form.Label htmlFor="phoneNumber" style={{ display: "flex" }}>
              <div className="labelOverride">Phone Number</div>
              {event.eventConfig.PhoneNumberRequired && (
                <div className="required">*</div>
              )}
            </Form.Label>
            <Form.Control
              type="number"
              name="PhoneNumber"
              id="phoneNumber"
              placeholder="Phone #"
              value={student.PhoneNumber}
              onChange={(e) => handleChange(e)}
            />
            <div className="red">{formErrors.PhoneNumber}</div>
          </Form.Group>
        )}
        {event.eventConfig.SchoolVisible && (
          <Form.Group>
            <Form.Label htmlFor="school" style={{ display: "flex" }}>
              <div className="labelOverride">School</div>
              {event.eventConfig.SchoolRequired && (
                <div className="required">*</div>
              )}
            </Form.Label>
            <Select
              type="select"
              placeholder={"Select..."}
              name="School"
              id="School"
              options={
                event.eventConfig.Schools
                  ? event.eventConfig.Schools.map((school) => {
                      return { label: school, value: school };
                    })
                  : []
              }
              value={
                student.School
                  ? { value: student.School, label: student.School }
                  : ""
              }
              onChange={(e) => handleDropdownChange("School", e.value)}
            />
            {student.School === "Other" && (
              <Form.Control
                type="text"
                name="Other"
                id="Other"
                placeholder="Enter another school..."
                value={
                  student.School === "Other" ? otherSchool : student.School
                }
                onChange={(e) => handleOtherSchoolChange(e)}
              />
            )}
            <div className="red">{formErrors.School}</div>
          </Form.Group>
        )}
        {event.eventConfig.GradMonthVisible && (
          <Form.Group>
            <Form.Label htmlFor="gradMonth" style={{ display: "flex" }}>
              <div className="labelOverride">Graduation Month</div>
              {event.eventConfig.GradMonthRequired && (
                <div className="required">*</div>
              )}
            </Form.Label>
            <Select
              type="select"
              placeholder={"Select..."}
              name="GradMonth"
              id="gradMonth"
              options={monthOptions}
              value={
                student.GradMonth
                  ? { value: student.GradMonth, label: student.GradMonth }
                  : ""
              }
              onChange={(e) => handleDropdownChange("GradMonth", e.value)}
            />
            <div className="red">{formErrors.GradMonth}</div>
          </Form.Group>
        )}
        {event.eventConfig.GradYearVisible && (
          <Form.Group>
            <Form.Label htmlFor="gradYear" style={{ display: "flex" }}>
              <div className="labelOverride">Graduation Year</div>
              {event.eventConfig.GradYearRequired && (
                <div className="required">*</div>
              )}
            </Form.Label>
            <Select
              type="select"
              placeholder={"Select..."}
              typename="GradYear"
              id="gradYear"
              options={
                event.eventConfig.Years
                  ? event.eventConfig.Years.map((year) => {
                      return { label: year, value: year };
                    })
                  : []
              }
              value={
                student.GradYear
                  ? { value: student.GradYear, label: student.GradYear }
                  : ""
              }
              onChange={(e) => handleDropdownChange("GradYear", e.value)}
            />
            <div className="red">{formErrors.GradYear}</div>
          </Form.Group>
        )}
        {event.eventConfig.MajorVisible && (
          <Form.Group>
            <Form.Label htmlFor="major" style={{ display: "flex" }}>
              <div className="labelOverride">Major</div>
              {event.eventConfig.MajorRequired && (
                <div className="required">*</div>
              )}
            </Form.Label>
            <Select
              type="select"
              placeholder={"Select..."}
              name="Major"
              id="Major"
              options={
                event.eventConfig.Majors
                  ? event.eventConfig.Majors.map((major) => {
                      return { label: major, value: major };
                    })
                  : []
              }
              isMulti
              value={
                student.Major
                  ? student.Major.map((tag) => {
                      return { value: tag, label: tag };
                    })
                  : ""
              }
              onChange={(e) => handleMultiSelectDropdownChange("Major", e)}
            />
            {student.Major.includes("Other") && (
              <Form.Control
                type="text"
                name="Other"
                id="Other"
                placeholder="Enter Antoher Major..."
                value={otherMajor}
                onChange={(e) => handleOtherMajorChange(e)}
              />
            )}
            <div className="red">{formErrors.Major}</div>
          </Form.Group>
        )}
        {event.eventConfig.GPAVisible && (
          <Form.Group>
            <Form.Label style={{ display: "flex" }}>
              <div className="labelOverride">GPA</div>
              {event.eventConfig.GPARequired && (
                <div className="required">*</div>
              )}
            </Form.Label>
            <Form.Control
              type="number"
              name="GPA"
              id="gpa"
              placeholder="GPA"
              value={student.GPA}
              onChange={(e) => handleChange(e)}
            />
            <div className="red">{formErrors.GPA}</div>
          </Form.Group>
        )}
        {event.eventConfig.SponsorshipVisible && (
          <Form.Group>
            <Form.Label htmlFor="sponsorship" style={{ display: "flex" }}>
              <div className="labelOverride">
                Are you currently on a Visa to attend school, such as an H1-B,
                or OPT/CPT Visa?
              </div>
              {event.eventConfig.SponsorshipRequired && (
                <div className="required">*</div>
              )}
            </Form.Label>
            <Form.Group>
              <Form.Label>
                <Form.Check
                  type="radio"
                  key="sponsorship-yes"
                  checked={student.Sponsorship}
                  label="Yes"
                  value="Yes"
                  name="Sponsorship"
                  onChange={(e) => handleYesNoRadioChange(e)}
                />{" "}
              </Form.Label>
              <br></br>
              <Form.Label>
                <Form.Check
                  type="radio"
                  key="sponsorship-no"
                  checked={student.Sponsorship === false}
                  label="No"
                  value="No"
                  name="Sponsorship"
                  onChange={(e) => handleYesNoRadioChange(e)}
                />{" "}
              </Form.Label>
            </Form.Group>
            <div className="red">{formErrors.Sponsorship}</div>
          </Form.Group>
        )}
        {event.eventConfig.PreviouslyJJVisible && (
          <Form.Group>
            <Form.Label htmlFor="previously" style={{ display: "flex" }}>
              <div className="labelOverride">
                Have you worked at J&J previously?
              </div>
              {event.eventConfig.PreviouslyJJRequired && (
                <div className="required">*</div>
              )}
            </Form.Label>
            <Form.Group>
              <Form.Label>
                <Form.Check
                  type="radio"
                  key="previously-yes"
                  checked={student.PreviouslyJJ}
                  label="Yes"
                  value="Yes"
                  name="PreviouslyJJ"
                  onChange={(e) => handleYesNoRadioChange(e)}
                />{" "}
              </Form.Label>
              <br></br>
              <Form.Label>
                <Form.Check
                  type="radio"
                  key="previously-no"
                  checked={student.PreviouslyJJ === false}
                  label="No"
                  value="No"
                  name="PreviouslyJJ"
                  onChange={(e) => handleYesNoRadioChange(e)}
                />{" "}
              </Form.Label>
            </Form.Group>
            <div className="red">{formErrors.PreviouslyJJ}</div>
          </Form.Group>
        )}
        {event.eventConfig.RelocationVisible && (
          <Form.Group>
            <Form.Label style={{ display: "flex" }}>
              <div className="labelOverride">Are you willing to relocate?</div>
              {event.eventConfig.RelocationRequired && (
                <div className="required">*</div>
              )}
            </Form.Label>
            <Form.Group>
              <Form.Label>
                <Form.Check
                  type="radio"
                  key="relocation-yes"
                  checked={student.Relocation}
                  label="Yes"
                  value="Yes"
                  name="Relocation"
                  onChange={(e) => handleYesNoRadioChange(e)}
                />{" "}
              </Form.Label>
              <br></br>
              <Form.Label>
                <Form.Check
                  type="radio"
                  key="previously-no"
                  checked={student.Relocation === false}
                  label="No"
                  value="No"
                  name="Relocation"
                  onChange={(e) => handleYesNoRadioChange(e)}
                />{" "}
              </Form.Label>
            </Form.Group>
            <div className="red">{formErrors.Relocation}</div>
          </Form.Group>
        )}
        {event.eventConfig.RoleInterestVisible && (
          <Form.Group>
            <Form.Label style={{ display: "flex" }}>
              <div className="labelOverride">
                What type of role are you interested in?
              </div>
              {event.eventConfig.RoleInterestRequired && (
                <div className="required">*</div>
              )}
            </Form.Label>
            <p style={{ fontSize: 12 }}>
              Interns are 12 week positions from late May – early August. Co-ops
              are 6 month positions from January to July. Leadership
              Development Program (LDP) is for individuals who have graduated
              (or will graduate) within 1 year of the Spring 2025 start date.
            </p>
            <Form.Group>
              {event.eventConfig.Roles.map((role) => {
                return (
                  <Form.Group key={role}>
                    <Form.Label className="checkboxSpace">
                      <Form.Check
                        type="radio"
                        checked={student.RoleInterest === role}
                        label={role}
                        name="RoleInterest"
                        value={role}
                        onChange={(e) => handleChange(e)}
                      />
                    </Form.Label>
                    <br></br>
                  </Form.Group>
                );
              })}
            </Form.Group>
            <Form.Control.Feedback className="red" type="invalid">
              {formErrors.RoleInterest}
            </Form.Control.Feedback>
          </Form.Group>
        )}
        {event.eventConfig.CapabilityInterestVisible && (
          <Form.Group>
            <Form.Label style={{ display: "flex" }}>
              <div className="labelOverride">
                What positions are you interested in?
              </div>
              {event.eventConfig.CapabilityInterestRequired && (
                <div className="required">*</div>
              )}
            </Form.Label>
            <Form.Group>
              {event.eventConfig.Capabilities.map((position) => {
                return (
                  <Form.Check
                    type="checkbox"
                    id={`positionCheckbox-${position}`}
                    name={"CapabilityInterest"}
                    value={position}
                    key={position}
                    label={position}
                    checked={student.CapabilityInterest.includes(position)}
                    onChange={(e) => handleMultiselectChange(e)}
                  />
                );
              })}
            </Form.Group>
            <div className="red">{formErrors.CapabilityInterest}</div>
          </Form.Group>
        )}
        {event.eventConfig.ResumeVisible && (
          <Form.Group>
            <Form.Label htmlFor="resume" style={{ display: "flex" }}>
              <div className="labelOverride">Resume</div>
              {event.eventConfig.ResumeRequired && (
                <div className="required">*</div>
              )}
            </Form.Label>
            <div>
              {student.Resume ? (
                <p>
                  Current resume on file:{" "}
                  <Link to={student.Resume}>{student.Resume}</Link>
                </p>
              ) : null}
            </div>
            <Form.Control
              type="file"
              name="Resume"
              id="resume"
              onChange={(e) => handleFileUploadChange(e)}
            />
            <Form.Text color="muted" style={{ fontSize: 12 }}>
              Please, upload one file with the following types: .doc, .docx,
              .pdf, .png, .jpg, .jpeg, .pjpeg, .gif, .heic, and .heif with a
              maximum file size of 7MB.
            </Form.Text>
            <div className="red">{formErrors.ResumeFileName}</div>
          </Form.Group>
        )}
        <Form.Group>
          <Form.Group id="consent" className="consent">
            <Form.Check
              id="protection"
              name="Protection"
              key="protection"
              type="checkbox"
              checked={student.Protection}
              onChange={(e) => handleYesNoRadioChange(e)}
            />
            <Form.Label style={{ display: "flex" }}>
              I have read the Applicant Privacy Policy and consent for Johnson &
              Johnson to send me information about roles and recruitment
              activities.
              {event.eventConfig.ResumeRequired && (
                <div className="required">*</div>
              )}
            </Form.Label>
          </Form.Group>
          <a
            href="https://www.careers.jnj.com/job-applicant-privacy-policy"
            style={{ fontSize: 13 }}
          >
            Privacy Policy
          </a>
          &emsp;
          <a href="https://www.bridge2employment.org/" style={{ fontSize: 13 }}>
            Data Enrichment
          </a>
          &emsp;
          <div className="red">{formErrors.Protection}</div>
        </Form.Group>
        <Form.Group
          style={{
            display: "Flex",
            alignItems: "center",
          }}
        >
          <Button
            type="submit"
            disabled={isSubmitting}
            style={{
              height: "50px",
              width: "100px",
              borderRadius: 4,
              backgroundColor: "#c8132e",
              color: "#ffffff",
              borderColor: "#c8132e",
              marginTop: "1em",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { md: "22px", sm: "18px", xs: "18px" },
              }}
            >
              {!student.id ? "Submit" : "Update"}
            </Typography>
          </Button>
          {isSubmitting && (
            <FaSync
              style={{ fontSize: "24px", color: "#000093", marginLeft: ".5em" }}
              className="loaderIcon"
            />
          )}
        </Form.Group>
        <div className="red">{mainErrorMessage}</div>
      </Form>
    </Container>
  );
};

export default StudentForm;
