import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useAuthContext } from "../../utils/AuthContext";
import { useEventContext } from "../../utils/EventContext";

import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import { getStudentsByEventKey } from "../../controllers/students";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { FaSync } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import SignIn from "../../Components/SignIn";
import Header from "../../Components/Header";

/*
this is the quick loop up page to look at!
*/
const QuickLookup = () => {
  const app = useAuthContext();
  const event = useEventContext();

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [students, setStudents] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(false);

  //sort/search/filter
  const [recruiter, setRecruiter] = useState("");
  const [search, setSearch] = useState("");
  const [submissionOrder, setSubmissionOrder] = useState("desc");
  const [spokenTo, setSpokenTo] = useState("All");

  function getStudentRecords() {
    if (app.accountName) {
      setIsRefreshing(true);

      //Pull students to the table based on the event key
      getStudentsByEventKey(event.eventConfig.EventKey)
        .then((records) => {
          if (records) {
            let studentRecords = records.map((student) => {
              return student.fields;
            });

            const filteredStudents = filterStudentsRecords(studentRecords);
            setStudents(filteredStudents);
          } else {
            console.log("There was a problem getting student records");
          }
          setIsRefreshing(false);
        })
        .catch((error) => {
          setIsRefreshing(false);
        });
    }
  }

  function filterStudentsRecords(studentRecords) {
    //Adjust order of students by the sorting order decided by recruiter
    if (submissionOrder === "desc") {
      studentRecords = studentRecords.reverse();
    }

    //If recruiter wants to see students not spoken to,
    //check for those who don't have a rating or notes yet
    if (spokenTo === "Not spoken to") {
      console.log("not spoken to: ");
      // eslint-disable-next-line
      studentRecords = studentRecords.filter((student) => {
        if (!student.Notes && student.Rating === 0) return student;
      });
    }
    //If recruiter wants to see my candidates,
    //Grab the candidates who have the currently logged in user assigned as thier primary recruiter
    //Primary recruiter is assigned to a candidate when that recruiter is the first recruiter that candidate added a notes/rating for
    else if (spokenTo === "My candidates" && recruiter) {
      // eslint-disable-next-line
      studentRecords = studentRecords.filter((student) => {
        if (student.PrimaryRecruiter === recruiter) return student;
      });
    }

    return studentRecords;
  }

  useEffect(() => {
    //Require J&J login for this page
    app.initAuthSecurePage();
  }, [app]);

  useEffect(() => {
    //only grab student or event data if user is logged in
    if (app.accountName) {
      //Upon first load, check if event config is already set or if a key was provided
      event.verifyEventConfig(queryParams, navigate);
      if (event.eventConfig.EventKey) {
        getStudentRecords();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    app.accountName,
    event.eventConfig.EventKey,
    submissionOrder,
    spokenTo,
    recruiter,
  ]);

  function handleCandidateFilter(filter) {
    setSearch("");

    if (filter === "All") {
      setSpokenTo("All");
    } else if (filter === "My candidates") {
      setSpokenTo("My candidates");
      setRecruiter(app.getFullName());
    } else if (filter === "Not spoken to") {
      setRecruiter("");
      setSpokenTo("Not spoken to");
    }
  }

  function handleSubmissionOrderChange() {
    if (submissionOrder === "desc") setSubmissionOrder("asc");
    else setSubmissionOrder("desc");
  }

  function handleSearchChange(name) {
    setRecruiter("");
    setSearch(name);
  }

  return (
    <>
      <AuthenticatedTemplate>
        <Container className="Student">
          <Header></Header>
          <Form.Group
            style={{
              width: "32%",
              position: "absolute",
              top: "8%",
              right: "15%",
              fontWeight: "bold",
              color: "#000093",
            }}
          ></Form.Group>
          <>
            <Form>
              <InputGroup
                style={{
                  marginBottom: ".75em",
                  marginTop: ".25em",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Form.Control
                  onChange={(e) => handleSearchChange(e.target.value)}
                  placeholder="Quick Find"
                  style={{
                    borderRadius: 4,
                    borderColor: "#0x0x93",
                    width: "85%",
                    zIndex: 0,
                  }}
                  value={search}
                />
              </InputGroup>
            </Form>
            <Table bordered hover>
              <thead>
                <tr>
                  {spokenTo === "All" ? (
                    <th
                      className={spokenTo === "All" ? "selected" : "unselected"}
                      style={{
                        width: "25%",
                        color: "#FFF",
                        backgroundColor: "#000093",
                      }}
                      onClick={() => {
                        handleCandidateFilter("All");
                      }}
                    >
                      All
                    </th>
                  ) : (
                    <th
                      className={spokenTo === "All" ? "selected" : "unselected"}
                      style={{
                        width: "25%",
                        color: "#000093",
                        backgroundColor: "#FFF",
                        borderColor: "#000093",
                        borderRadius: 4,
                      }}
                      onClick={() => {
                        handleCandidateFilter("All");
                      }}
                    >
                      All
                    </th>
                  )}
                  {spokenTo === "Not spoken to" ? (
                    <th
                      style={{
                        width: "25%",
                        color: "#FFF",
                        backgroundColor: "#000093",
                      }}
                      onClick={() => {
                        handleCandidateFilter("Not spoken to");
                      }}
                    >
                      Not spoken to
                    </th>
                  ) : (
                    <th
                      style={{
                        width: "25%",
                        color: "#000093",
                        backgroundColor: "#FFF",
                        borderColor: "#000093",
                        borderRadius: 4,
                      }}
                      onClick={() => {
                        handleCandidateFilter("Not spoken to");
                      }}
                    >
                      Not spoken to
                    </th>
                  )}
                  {spokenTo === "My candidates" ? (
                    <th
                      style={{
                        width: "25%",
                        color: "#FFF",
                        backgroundColor: "#000093",
                      }}
                      onClick={() => {
                        handleCandidateFilter("My candidates");
                      }}
                    >
                      My candidates
                    </th>
                  ) : (
                    <th
                      style={{
                        width: "25%",
                        color: "#000093",
                        backgroundColor: "#FFF",
                        borderColor: "#000093",
                        borderRadius: 4,
                      }}
                      onClick={() => {
                        handleCandidateFilter("My candidates");
                      }}
                    >
                      My candidates
                    </th>
                  )}
                </tr>
              </thead>
            </Table>
            <Table bordered hover>
              <thead>
                <tr>
                  <th
                    onClick={() => {
                      getStudentRecords();
                    }}
                    style={{
                      color: "#FFF",
                      backgroundColor: "#000093",
                    }}
                  >
                    Candidates
                    <FaSync
                      className={isRefreshing ? "loaderIcon" : ""}
                      style={{ marginLeft: ".25em" }}
                    />
                  </th>
                  <th
                    style={{
                      color: "#FFF",
                      backgroundColor: "#000093",
                    }}
                    onClick={() => {
                      handleSubmissionOrderChange();
                    }}
                  >
                    Submitted
                    {submissionOrder === "desc" ? (
                      <FaChevronDown style={{ marginLeft: ".25em" }} />
                    ) : (
                      <FaChevronUp style={{ marginLeft: ".25em" }} d />
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {students
                  .filter((student) => {
                    return search.toLowerCase() === ""
                      ? student.Name
                      : student.Name.toLowerCase().includes(
                          search.toLowerCase()
                        );
                  })
                  .map((student, index) => (
                    <tr
                      key={index}
                      onClick={() => {
                        navigate(
                          `/preview?eventKey=${event.eventConfig.EventKey}&student=${student.id}`,
                          { state: student }
                        );
                      }}
                    >
                      <td style={{ minWidth: "30%" }}>{student.Name}</td>
                      <td style={{ minWidth: "5%", maxWidth: "10%" }}>
                        {student.SubmissionTime}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </>
        </Container>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SignIn></SignIn>
      </UnauthenticatedTemplate>
    </>
  );
};

export default QuickLookup;
