import React, { useState } from "react";
import { Form, Container } from "react-bootstrap";
import Header from "../../Components/Header";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import {
  getDefaultEventConfig,
  updateEventConfig,
} from "../../controllers/event";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaSync } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CreatableSelect from "react-select/creatable";

const ConfigureRecruitmentLeads = () => {
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [recruitmentLeads, setRecruitmentLeads] = useState([]);
  const [eventID, setEventID] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    getDefaultEventConfig().then((event) => {
      setRecruitmentLeads(event.RecruitmentLeads);
      setEventID(event.id);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleMultiSelectDropdownChange(event) {
    let leads = event.map((e) => {
      return e.value;
    });

    if (areLeadsValid(leads)) {
      setRecruitmentLeads(leads);
    }
  }

  const areLeadsValid = (leads = []) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z.]+jnj\.com$/i;

    for (const lead of leads) {
      if (!emailPattern.test(lead)) {
        setError("Please enter a valid email format: (username@its.jnj.com)");
        return false;
      }
    }

    setError("");
    return true;
  };

  async function submitForm(e) {
    e.preventDefault();

    if (areLeadsValid(recruitmentLeads)) {
      updateRecruitmentLeads();
    }
  }

  async function updateRecruitmentLeads() {
    setIsSubmitting(true);
    const updateData = {
      RecruitmentLeads: recruitmentLeads,
      "RecruitmentLeads@odata.type": "Collection(Edm.String)",
    };

    updateEventConfig(updateData, eventID)
      .then((eventConfig) => {
        setIsSubmitting(false);

        if (eventConfig) {
          navigate("/selectEvent");
        } else {
          throw Error("The recruitment leads were not updated successfully");
        }
      })
      .catch((error) => {
        console.log("Error updating recruitment leads: ", error);
        navigate("/updateEventError");
      });
  }

  return (
    <Container className="Student">
      <Header></Header>
      <Form className="Form" onSubmit={(e) => submitForm(e)}>
        <h3 className="text-center mt-3">Update Recruitment Leads</h3>
        <Button
          onClick={() => navigate(-1)}
          style={{
            margin: "1",
            backgroundColor: "#F5F5F5",
            borderRadius: 4,
          }}
        >
          <ArrowBackIcon style={{ color: "black" }} />
          <span
            style={{
              margin: "5px",
              color: "#060606",
            }}
          >
            Back
          </span>
        </Button>
        {
          <>
            <Form.Group>
              <h3>
                Please enter the J&J email addresses of the recruiters you want
                to be able to edit event configurations
              </h3>
            </Form.Group>
          </>
        }
        <Form.Group>
          <Form.Label htmlFor="Leads">Leads</Form.Label>
          <CreatableSelect
            type="select"
            placeholder={"Select..."}
            name="Tags"
            id="Tags"
            options={recruitmentLeads.map((lead) => {
              return { value: lead, label: lead };
            })}
            isMulti
            value={recruitmentLeads.map((lead) => {
              return { value: lead, label: lead };
            })}
            onChange={(e) => handleMultiSelectDropdownChange(e)}
          />
          <div className="red">{error}</div>
        </Form.Group>

        <Form.Group
          style={{
            display: "Flex",
            alignItems: "center",
          }}
        >
          <Button
            type="submit"
            disabled={isSubmitting}
            style={{
              height: "50px",
              width: "100px",
              borderRadius: 4,
              backgroundColor: "#c8132e",
              color: "#ffffff",
              borderColor: "#c8132e",
              marginTop: "1em",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { md: "22px", sm: "18px", xs: "18px" },
              }}
            >
              Update
            </Typography>
          </Button>
          {isSubmitting && (
            <FaSync
              style={{
                fontSize: "24px",
                color: "#000093",
                marginLeft: ".5em",
              }}
              className="loaderIcon"
            />
          )}
        </Form.Group>
      </Form>
    </Container>
  );
};

export default ConfigureRecruitmentLeads;
