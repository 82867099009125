import React, { useEffect } from "react";
import { useNavigate } from "react-router";

import Header from "../../Components/Header";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { Typography } from "@mui/material";
import SignIn from "../../Components/SignIn";
import { useAuthContext } from "../../utils/AuthContext";
import { UnauthenticatedTemplate } from "@azure/msal-react";

const RecruiterHome = () => {
  const app = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    app.initAuthSecurePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="Student">
      <Header></Header>
      <div style={{ display: "block", textAlign: "center" }}>
        <div
          style={{
            display: "Flex",
            margin: "2em",
            justifyContent: "center",
          }}
        >
          <UnauthenticatedTemplate>
            <SignIn></SignIn>
          </UnauthenticatedTemplate>
        </div>
        <Form>
          <Form.Group>
            <Button
              onClick={() => {
                navigate("/dashboard");
              }}
              style={{
                margin: "0.66em",
                borderRadius: "25px",
                backgroundColor: "#ffffff",
                color: "#000093",
                borderColor: "#000093",
                borderWidth: "4px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { md: "36px", sm: "28px", xs: "20px" },
                  padding: "1em",
                }}
              >
                {"Dashboard"}
              </Typography>
            </Button>
            <Form.Group>
              <Button
                style={{
                  margin: "0.66em",
                  borderRadius: "25px",
                  backgroundColor: "#000093",
                  borderColor: "#000093",
                  borderWidth: "4px",
                  color: "#ffffff",
                }}
                onClick={() => {
                  navigate("/lookup");
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: { md: "36px", sm: "28px", xs: "20px" },
                    padding: "1em",
                  }}
                >
                  {"Quick Lookup"}
                </Typography>
              </Button>
            </Form.Group>
          </Form.Group>
        </Form>
      </div>
    </Container>
  );
};

export default RecruiterHome;
