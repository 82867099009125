import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

//authentication
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import ProvideAuthContext from "./utils/AuthContext";
import ProvideEventContext from "./utils/EventContext";
import { useAuthContext } from "./utils/AuthContext";
import config from "./config/config";

//student pages
import StudentEventSelector from "./pages/student/StudentEventSelector";
import StudentForm from "./pages/student/StudentForm";
import ThankYou from "./pages/student/ThankYou";

//error pages
import CreateEventError from "./pages/error/CreateEventError";
import CreateRegistrationError from "./pages/error/CreateRegistrationError";
import GetEventError from "./pages/error/GetEventError";
import GetStudentError from "./pages/error/GetStudentError";
import UpdateEventError from "./pages/error/UpdateEventError";
import UpdateRegistrationError from "./pages/error/UpdateRegistrationError";
import UploadResumeError from "./pages/error/UploadResumeError";

//recruiter pages
import ConfigureEvent from "./pages/recruiter/ConfigureEvent";
import ConfigureRecruitmentLeads from "./pages/recruiter/ConfigureRecruitmentLeads";
import QuickLookup from "./pages/recruiter/QuickLookup";
import RecruiterHome from "./pages/recruiter/RecruiterHome";
import StudentDashboard from "./pages/recruiter/StudentDashboard";
import StudentNotes from "./pages/recruiter/StudentNotes";
import SelectEvent from "./pages/recruiter/SelectEvent";
import StudentPreview from "./pages/recruiter/StudentPreview";

import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: config.clientID,
    authority: `https://login.microsoftonline.com/${config.tenantID}`,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
});

const accounts = msalInstance.getAllAccounts();
if (accounts && accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const authResult = event.payload;
    msalInstance.setActiveAccount(authResult.account);
  }
});

export default function Page() {
  const app = useAuthContext();

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const authResult = event.payload;
      msalInstance.setActiveAccount(authResult.account);
      app.getAccountName();
    }
  });

  return (
    <BrowserRouter>
      <Routes>
        {/*student routes*/}
        <Route path="/" element={<StudentForm />}>
          <Route index element={<StudentForm />} />
        </Route>
        <Route path="/thanks" element={<ThankYou />}></Route>
        <Route
          path="/studentEventSelector"
          element={<StudentEventSelector />}
        ></Route>

        {/*recruiter routes*/}
        <Route path="/recruiter" element={<RecruiterHome />}></Route>
        <Route path="/dashboard" element={<StudentDashboard />}></Route>
        <Route path="/configureEvent" element={<ConfigureEvent />}></Route>
        <Route path="/lookup" element={<QuickLookup />}></Route>
        {<Route path="/preview" element={<StudentPreview />}></Route>}
        <Route path="/selectEvent" element={<SelectEvent />}></Route>
        <Route
          path="/selectRecruitmentLeads"
          element={<ConfigureRecruitmentLeads />}
        ></Route>
        <Route path="/student" element={<StudentNotes />}></Route>
        <Route path="/student/edit" element={<StudentForm />}></Route>

        {/*error routes*/}
        <Route path="/createEventError" element={<CreateEventError />}></Route>
        <Route
          path="/createRegistrationError"
          element={<CreateRegistrationError />}
        ></Route>
        <Route path="/getEventError" element={<GetEventError />}></Route>
        <Route path="/getStudentError" element={<GetStudentError />}></Route>
        <Route path="/updateEventError" element={<UpdateEventError />}></Route>
        <Route
          path="/updateRegistrationError"
          element={<UpdateRegistrationError />}
        ></Route>
        <Route
          path="/uploadResumeError"
          element={<UploadResumeError />}
        ></Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <ProvideAuthContext>
        <ProvideEventContext>
          <Page />
        </ProvideEventContext>
      </ProvideAuthContext>
    </MsalProvider>
  </React.StrictMode>
);
