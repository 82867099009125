export const fieldNames = [
  "Name",
  "Email",
  "PhoneNumber",
  "School",
  "GradMonth",
  "GradYear",
  "GPA",
  "Sponsorship",
  "PreviouslyJJ",
  "Relocation",
  "Major",
  "RoleInterest",
  "CapabilityInterest",
  "Resume",
];
