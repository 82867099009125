import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { getEventConfigByKey } from "../../controllers/event";
import { useEventContext } from "../../utils/EventContext";
import { useNavigate } from "react-router";
import { FaSync } from "react-icons/fa";
import { Typography } from "@mui/material";
import Header from "../../Components/Header";

const StudentEventSelector = () => {
  const [key, setKey] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const event = useEventContext();

  const handleKeyChange = (event) => {
    setKey(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsSubmitting(true);
      setError("");
      const eventConfig = await getEventConfigByKey(key.toLowerCase());
      setIsSubmitting(false);

      if (
        eventConfig &&
        eventConfig.EventKey.toLowerCase() === key.toLowerCase()
      ) {
        event.setEventConfig(eventConfig);
        navigate(`/?eventKey=${key.toLowerCase()}`);
      } else {
        setError("Event not found.");
      }
    } catch (error) {
      console.error("Error fetching event:", error);
      setError("Error fetching data. Please try again.");
    }
  };

  return (
    <Container className="Student">
      <Header></Header>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Form.Group controlId="formKey" onSubmit={handleSubmit}>
          <Form.Label>Enter Event Key:</Form.Label>
          <Form.Control
            style={{ maxWidth: "50%", display: "Flex", alignItems: "center" }}
            type="text"
            value={key}
            onChange={handleKeyChange}
          />
        </Form.Group>
        <Form.Group style={{ display: "flex", alignItems: "center" }}>
          <Button
            type="submit"
            disabled={isSubmitting}
            style={{
              borderRadius: 4,
              backgroundColor: "#c8132e",
              color: "#ffffff",
              borderColor: "#c8132e",
              marginTop: "1em",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { md: "18px", sm: "16px", xs: "14px" },
              }}
            >
              {"Submit"}
            </Typography>
          </Button>
          {isSubmitting && (
            <FaSync
              style={{ fontSize: "24px", color: "#000093", marginLeft: ".5em" }}
              className="loaderIcon"
            />
          )}
        </Form.Group>
        <div className="red">{error}</div>
      </Form>
    </Container>
  );
};

export default StudentEventSelector;
