import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { Container } from "react-bootstrap";
import Header from "../../Components/Header";
import { FaTimesCircle } from "react-icons/fa";
import { useAuthContext } from "../../utils/AuthContext";
import { Button } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";

const GetStudentError = () => {
  const navigate = useNavigate();

  const app = useAuthContext();
  useEffect(() => {
    app.initAuthPublicPage();
  });

  return (
    <Container className="Student">
      <Header></Header>
      <Button
        onClick={() => navigate("/lookup")}
        style={{
          margin: "1",
          backgroundColor: "#F5F5F5",
          borderRadius: 4,
        }}
      >
        <ArrowBackIcon style={{ color: "black" }} />
        <span
          style={{
            margin: "5px",
            color: "#060606",
          }}
        >
          Back
        </span>
      </Button>
      <div style={{ textAlign: "center" }}>
        <FaTimesCircle style={{ fontSize: "64px", color: "#d9534f" }} />
        <h1 style={{ fontSize: "24px", marginTop: "20px" }}>Oh No!</h1>
        <h4 style={{ fontSize: "18px", marginTop: "10px" }}>
          There was an issue getting a record for this student
        </h4>
        <h4 style={{ fontSize: "18px", marginTop: "10px" }}>
          Try searching again in <Link to="/lookup">Quick Lookup</Link>
        </h4>
      </div>
    </Container>
  );
};

export default GetStudentError;
