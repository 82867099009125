import React, { useEffect, useState } from "react";

import { useAuthContext } from "../../utils/AuthContext";
import { useEventContext } from "../../utils/EventContext";

import { useLocation, useNavigate } from "react-router-dom";
import { Rating, Typography } from "@mui/material";
import { Form, Button, Container } from "react-bootstrap";
import Select from "react-select";
import FormResponse from "../../Components/FormResponse";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { updateSharepointRecord } from "../../controllers/students";
import { getStudent } from "../../controllers/students";

import Header from "../../Components/Header";
import SignIn from "../../Components/SignIn";
import { FaSync } from "react-icons/fa";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const StudentNotes = () => {
  const app = useAuthContext();
  const event = useEventContext();

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [student, setStudent] = useState({});
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  function verifyLinkParameters() {
    if (!queryParams.get("eventKey") && !event.eventConfig.EventKey) {
      navigate("/selectEvent");
    } else if (queryParams.get("eventKey") && !queryParams.get("student")) {
      navigate(`/lookup?eventKey=${queryParams.get("eventKey")}`);
    } else if (event.eventConfig.EventKey && !queryParams.get("student")) {
      navigate(`/lookup?eventKey=${event.eventConfig.EventKey}`);
    }
  }

  function verifyStudentData() {
    if (app.getAccountName()) {
      if (state && state.Name) {
        setStudent(state);
      }

      getStudent(queryParams.get("student"))
        .then((newStudent) => {
          console.log("Student from sharepoint in student view: ", newStudent);
          if (
            newStudent &&
            newStudent.id &&
            newStudent.id === queryParams.get("student")
          ) {
            if (!newStudent.PrimaryRecruiter) {
              console.log("No primary recruiter");
              newStudent.PrimaryRecruiter = app.getFullName();
            }
            setStudent(newStudent);
          } else {
            navigate("/getStudentError");
          }
        })
        .catch((error) => {
          console.log("Get Student Error: ", error);
          navigate("/getStudentError");
        });
    }
  }

  useEffect(() => {
    app.initAuthSecurePage();
    verifyLinkParameters();
    if (app.accountName) {
      event.verifyEventConfig(queryParams, navigate);
      verifyStudentData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.accountName, event.eventConfig]);

  function handleRatingChange(event, value) {
    setStudent({ ...student, Rating: value });
  }

  function handleTagChange(event) {
    let values = event.map((e) => {
      return e.value;
    });

    setStudent({ ...student, Tags: values });
  }

  function handleChange(event) {
    const { target } = event;
    const value = target.value;
    const { name } = target;
    setStudent({ ...student, [name]: value });
  }

  async function onSubmit(e) {
    e.preventDefault();

    setIsSubmitting(true);
    let recruiter = app.getFullName();

    const updateData = {
      Rating: student.Rating,
      Notes: student.Notes,
      NotesHistory: student.NotesHistory,
      Tags: student.Tags,
      PrimaryRecruiter: student.PrimaryRecruiter,
    };

    try {
      await updateSharepointRecord(updateData, student.id, recruiter).then(
        (updatedRecord) => {
          setStudent(updatedRecord);
          setMessage("");
          setIsSubmitting(false);
          navigate(`/lookup?eventKey=${event.eventConfig.EventKey}`);
        }
      );
    } catch (error) {
      console.log(error);
      setMessage(
        "There was an error updating this student's record. Please save your note or it might be lost."
      );
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <AuthenticatedTemplate>
        <Container className="Student">
          <Header></Header>
          <Form.Group
            style={{
              width: "32%",
              position: "absolute",
              top: "8%",
              right: "15%",
              fontWeight: "bold",
              color: "#000093",
            }}
          ></Form.Group>
          <Form className="Form" onSubmit={(e) => onSubmit(e)}>
            <Form.Group style={{ display: "flex" }}>
              <Button
                onClick={() => {
                  navigate(
                    `/preview?eventKey=${event.eventConfig.EventKey}&student=${student.id}`
                  );
                }}
                style={{
                  margin: "1",
                  backgroundColor: "#F5F5F5",
                  borderRadius: 4,
                }}
              >
                <ArrowBackIcon style={{ color: "black" }} />
                <span
                  style={{
                    margin: "5px",
                    color: "#060606",
                  }}
                >
                  Back
                </span>
              </Button>
              <Button
                style={{
                  marginLeft: ".5em",
                  backgroundColor: "#F5F5F5",
                  borderRadius: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  setIsRefreshing(true);
                  getStudent(queryParams.get("student"))
                    .then((newStudent) => {
                      if (newStudent && newStudent.Name) {
                        setStudent(newStudent);
                      }
                      setIsRefreshing(false);
                    })
                    .catch((error) => {
                      setIsRefreshing(false);
                    });
                }}
              >
                <FaSync
                  style={{
                    fontSize: "16px",
                    color: "#060606",
                    fontWeight: "lighter",
                  }}
                  className={isRefreshing ? "loaderIcon" : ""}
                />
              </Button>
            </Form.Group>
            <Form.Group>
              {student.Name && <h2>{student.Name}</h2>}
              {student.Name &&
                student.RoleInterest &&
                student.CapabilityInterest && (
                  <h4>{`Seeking ${
                    student.RoleInterest
                  } role in ${student.CapabilityInterest.join(", ")}`}</h4>
                )}
            </Form.Group>
            <div style={{ display: "flex" }}>
              <div
                onClick={() => {
                  navigate(
                    `edit?eventKey=${event.eventConfig.EventKey}&student=${student.id}`,
                    { state: student }
                  );
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#F5F5F5",
                    color: "#000",
                    textDecoration: "none",
                  }}
                >
                  Edit Responses
                </Button>
              </div>
              {student.Sponsorship && (
                <div
                  style={{
                    backgroundColor: "#c8132e",
                    borderRadius: "4em",
                    padding: ".25em",
                    color: "#ffffff",
                    fontWeight: "bold",
                    marginLeft: "1em",
                    paddingTop: ".5em",
                  }}
                >
                  Requires Sponsorship
                </div>
              )}
              {student.GPA < 3.0 && (
                <div
                  style={{
                    backgroundColor: "#c8132e",
                    borderRadius: "4em",
                    padding: ".25em",
                    color: "#ffffff",
                    fontWeight: "bold",
                    marginLeft: "1em",
                    paddingTop: ".5em",
                  }}
                >
                  Low GPA
                </div>
              )}
            </div>
            {student.Name && <FormResponse student={student}></FormResponse>}
            <Form.Label htmlFor="Rating">Rating</Form.Label>
            <Form.Group>
              <Rating
                name="Rating"
                id="Rating"
                onChange={(e, value) => handleRatingChange(e, value)}
                max={10}
                size="large"
                precision={0.5}
                sx={{
                  fontSize: "2.5rem",
                }}
                value={Number(student.Rating) ?? 0}
              ></Rating>
            </Form.Group>
            <Form.Label htmlFor="Tags">Tags</Form.Label>
            <Form.Group style={{ marginBottom: ".5em" }}>
              <Select
                type="select"
                placeholder={"Select..."}
                name="Tags"
                id="Tags"
                options={
                  event.eventConfig.Tags
                    ? event.eventConfig.Tags.map((major) => {
                        return { label: major, value: major };
                      })
                    : []
                }
                value={
                  student.Tags
                    ? student.Tags.map((tag) => {
                        return { value: tag, label: tag };
                      })
                    : ""
                }
                isMulti
                onChange={(e) => handleTagChange(e)}
              />
            </Form.Group>
            <Form.Label htmlFor="Notes">Notes</Form.Label>
            <Form.Group style={{ marginBottom: ".5em" }}>
              <Form.Control
                name="Notes"
                id="Notes"
                as="textarea"
                placeholder="Add New Note..."
                onChange={(e) => handleChange(e)}
                style={{
                  margin: "1",
                  backgroundColor: "#F5F5F5",
                  borderRadius: 4,
                  maxWidth: "425px",
                }}
                value={student.Notes ?? ""}
                rows={5}
              />
            </Form.Group>
            <Form.Label htmlFor="Rating">History</Form.Label>
            {student.NotesHistory &&
              student.NotesHistory.map((record, index) => {
                return (
                  <Form.Group
                    key={index}
                    style={{
                      margin: ".25em",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      id={index}
                      sx={{
                        fontSize: "15px",
                        justifyContent: "center",
                      }}
                    >
                      {record}
                    </Typography>
                  </Form.Group>
                );
              })}
            <div className="red">{message}</div>
            <Form.Group
              style={{
                display: "Flex",
                alignItems: "center",
              }}
            >
              <Button
                type="submit"
                disabled={isSubmitting}
                style={{
                  height: "50px",
                  width: "100px",
                  borderRadius: 4,
                  backgroundColor: "#c8132e",
                  color: "#ffffff",
                  borderColor: "#c8132e",
                  marginTop: "1em",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: { md: "22px", sm: "18px", xs: "18px" },
                  }}
                >
                  {"Submit"}
                </Typography>
              </Button>
              {isSubmitting && (
                <FaSync
                  style={{
                    fontSize: "24px",
                    color: "#000093",
                    marginLeft: ".5em",
                  }}
                  className="loaderIcon"
                />
              )}
            </Form.Group>
          </Form>
        </Container>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SignIn></SignIn>
      </UnauthenticatedTemplate>
    </>
  );
};

export default StudentNotes;
