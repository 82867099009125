import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Box, Rating } from "@mui/material";
import { getStudentsByEventKey } from "../controllers/students";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../utils/AuthContext";
import { useEventContext } from "../utils/EventContext";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

const StudentsTable = (props) => {
  const app = useAuthContext();
  const event = useEventContext();

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [rows, setRows] = React.useState([]);
  const [isRefreshing, setIsRefreshing] = React.useState(false);

  function getStudentRecords() {
    if (app.accountName) {
      setIsRefreshing(true);
      getStudentsByEventKey(event.eventConfig.EventKey)
        .then((records) => {
          if (records) {
            if (records) {
              const newRows = records.map((student) => {
                return student.fields;
              });

              setRows(newRows);
              setIsRefreshing(false);
            }
          } else {
            console.log("There was a problem getting student records");
          }
          setIsRefreshing(false);
        })
        .catch((error) => {
          setIsRefreshing(false);
        });
    }
  }

  useEffect(() => {
    app.initAuthSecurePage();
  }, [app]);

  useEffect(() => {
    if (app.accountName) {
      event.verifyEventConfig(queryParams, navigate);
      if (event.eventConfig.EventKey) {
        getStudentRecords();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.accountName, event.eventConfig.EventKey]);

  return (
    <Box
      m="1.5rem 2.5rem"
      height="80%"
      width="100%"
      maxWidth="90%"
      paddingLeft="5%"
    >
      <DataGrid
        IsRefreshing={isRefreshing || !rows}
        getRowId={(row) => row.id}
        rows={rows || []}
        initialState={{
          pinnedColumns: {
            left: ["Name"],
          },
        }}
        slots={{
          toolbar: CustomToolbar,
        }}
        columns={columnOptions}
      />
    </Box>
  );
};

const columnOptions = [
  {
    field: "Name",
    headerName: "Name",
    minWidth: 100,
    width: 100,
    flex: 1,
    renderCell: (rowData) => (
      <Link to={`/preview?&student=${rowData.id}`}>{rowData.value}</Link>
    ),
  },
  {
    field: "GradYear",
    headerName: "Grad Year",
    minWidth: 50,
    width: 50,
    flex: 1,
  },
  {
    field: "Rating",
    type: "number",
    minWidth: 80,
    width: 80,
    headerName: "Rating",
    flex: 0.5,
    renderCell: (rowData) => {
      if (rowData.value > 0) {
        return (
          <Rating
            value={Number(rowData.value)}
            max={4}
            precision={0.5}
            readOnly
          />
        );
      } else {
        return <div value={0}>None</div>;
      }
    },
  },
  {
    field: "Major",
    minWidth: 100,
    width: 100,
    headerName: "Major",
    flex: 1,
  },
  {
    field: "RoleInterest",
    minWidth: 50,
    width: 50,
    headerName: "Role",
    flex: 1,
  },
  {
    field: "CapabilityInterest",
    header: "Capability",
    minWidth: 125,
    width: 125,
    renderCell: (capabilities) => {
      return (
        <ul className="flex">
          {capabilities.value ? (
            capabilities.value.map((capability, index) => (
              <li key={index}>{capability}</li>
            ))
          ) : (
            <p>none</p>
          )}
        </ul>
      );
    },
    flex: 1,
  },
  {
    field: "Notes",
    header: "Notes",
    minWidth: 125,
    width: 125,
    flex: 1,
  },
  {
    field: "Tags",
    header: "Tags",
    minWidth: 125,
    width: 125,
    renderCell: (tags) => {
      if (tags.value) {
        return (
          <ul className="flex">
            {tags.value.map((tag, index) => (
              <li key={index}>{tag}</li>
            ))}
          </ul>
        );
      }
    },
    flex: 1,
  },
];

export default StudentsTable;
