import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../utils/AuthContext";
import { Container } from "react-bootstrap";
import Header from "../../Components/Header";
import { FaCheckCircle } from "react-icons/fa";
import { useLocation } from "react-router";

const ThankYou = () => {
  const app = useAuthContext();
  const state = useLocation().state;
  const [applicationLinks, setApplicationLinks] = useState(null);

  useEffect(() => {
    app.initAuthPublicPage();
  }, [app]);

  useEffect(() => {
    setApplicationLinks(state);
  }, [state]);

  return (
    <Container className="Student">
      <Header></Header>
      <div style={{ textAlign: "center" }}>
        <FaCheckCircle style={{ fontSize: "64px", color: "#000093" }} />
        <h1 style={{ fontSize: "24px", marginTop: "20px" }}>Thank You!</h1>
        <h4 style={{ fontSize: "18px", marginTop: "10px" }}>
          Thank you for submitting your registration form!
        </h4>
        <br></br>
        {applicationLinks && Object.keys(applicationLinks).length > 0 && (
          <h4 style={{ fontWeight: "Bold"}}>
            Be sure to apply for the roles you're interested in through the links below:
          </h4>
        )}
        {applicationLinks &&
          Object.entries(applicationLinks).map(([role, link]) => (
            <div key={role} style={{ marginBottom: "10px", fontSize: "15px" }}>
              <strong>{role}:</strong> <a href={link}>{link}</a>
            </div>
          ))}
        <br></br>
        <h4>
          Don't see the role you're looking for? Find all our available
          opportunities at{" "}
          <a href="https://www.careers.jnj.com/">careers.jnj.com</a>
        </h4>
      </div>
    </Container>
  );
};

export default ThankYou;
